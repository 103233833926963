import React from 'react';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import QueueIcon from '@material-ui/icons/Queue';
import ErrorIcon from '@material-ui/icons/Error';
import {makeStyles} from "@material-ui/core/styles";
import {amber, green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  done: {
    color: green[600],
  },
  error: {
    color: amber[700],
  }
}))


export default function StatusIcon(props) {
  const {status} = props;
  const classes = useStyles();

  const TypeIcon = ({type}) => {
    let typeIcon;

    switch (type) {
      case 0:
        typeIcon = <CloudUploadIcon />
        break;
      case 1:
        typeIcon = <CloudDoneIcon className={classes.done} />
        break;
      case 2:
        typeIcon = <ErrorIcon className={classes.error} />
        break;
      case 3:
        typeIcon = <CloudOffIcon/>
        break;
      case 4:
        typeIcon = <QueueIcon/>
        break;
      default:
        typeIcon = <CloudOffIcon/>
    }

    return typeIcon;
  }

  return (
    <TypeIcon type={status}/>
  )
}
