import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";

// material UI
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

// component
import CustomTable from "../../components/CustomTable";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import { utilsContext } from "../../contexts/utils";

import resources from "../../api/resources";
import useQuery from "../../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  special: {
    color: "red",
    fontSize: 30,
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary,
  },
  tableWrapper: {
    padding: 0,
  },
  sections: {
    backgroundColor: "yellow",
  },
  filterWrapper: {
    display: "grid",
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10,
  },
  inputWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  inputWidth: {
    width: 220,
    marginTop: 16,

    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
  },
  // component
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  searchButton: {
    borderRadius: "0 0 5px 5px",
  },
  // common
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

const header = [
  { id: "id", label: "ID", disabledSort: true },
  { id: "name", label: "Video Name" },
  { id: "categories", label: "Categories", disabledSort: true },
  { id: "createdAt", label: "Created At" },
  { id: "action", label: "Action", disabledSort: true },
];

const generateBody = (items, handleEditVideos, handleDelete, classes) => {
  return items.map((e) => ({
    id: e.ID,
    name: e.resourceName,
    categories: e.category,
    createdAt: e.createdAt,
    action: (
      <div className={clsx(classes.flexCenter, classes.flexJustifySpaceEvenly)}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditVideos(e.ID)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.deleteButton}
          onClick={handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    ),
  }));
};

function VideoList() {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    pageItems: 15,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    startDate: null,
    endDate: null,
    body: [],
    hardReload: false,
    deleteID: "",
    deleteName: "",
    searchValue: "",
    searchTagValue: query.searchTag ? query.searchTag : "",
    searchModelValue: query.searchModel ? query.searchModel : "",
    searchStudioValue: query.studio ? query.studio : "",
    categoryValue: query.category ? query.category : "",
    categoryOption: [{ value: "", label: "None" }],
    getCount: 0,
  });

  useEffect(() => {
    async function inital() {
      await getCategory();
    }
    inital();
  }, []);

  useEffect(() => {
    async function resources() {
      await getVideos();
    }
    setMainState({ getCount: mainState.getCount + 1 });
    resources();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.hardReload,
  ]);

  // common functions
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleEditVideos = (id) => (events) => {
    setRedirect(`/videos/${id}/edit`);
  };

  const handleDateChange = (key) => (event) => {
    setMainState({ [key]: event });
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  // API functions
  const getCategory = async () => {
    const result = await resources.getCategories();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get categories",
      });
    }

    let categoryOption = [...mainState.categoryOption];
    data.forEach((e) => {
      categoryOption.push({ value: e.ID, label: e.name });
    });

    setMainState({ categoryOption });
  };

  const getVideos = async () => {
    _handleChange({ openBackdrop: true });
    const {
      startDate,
      endDate,
      rowsPerPage,
      categoryValue,
      searchTagValue,
      searchModelValue,
      searchStudioValue,
      searchValue,
      order,
      orderBy,
      page,
      getCount,
    } = mainState;
    const params = {
      page: page,
      limit: rowsPerPage,
      categoryId: categoryValue,
      keyword: searchValue,
      keywordModel: searchModelValue,
      keywordTag: searchTagValue,
      keywordStudio: searchStudioValue,
      startAt: startDate && moment(startDate).format("YYYY-MM-DD"),
      endAt: endDate && moment(endDate).format("YYYY-MM-DD"),
      sort: orderBy,
      sortBy: order,
    };
    const result = await resources.getVideoList(params);
    const { success, data } = result;

    _handleChange({ openBackdrop: false });

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: data.message,
      });
    }

    setMainState({
      body: data.videoList,
      pageItems: data.totals,
      page: page,
    });

    if (getCount !== 0) setQuery(params);
  };

  const handleSearch = async () => {
    await getVideos();
  };

  const handleDelete = (details) => async () => {
    setMainState({ deleteID: details.ID, deleteVideo: details.resourceName });
    setOpenDialog(true);
  };

  const deleteVideo = async () => {
    const { deleteID, hardReload, page } = mainState;

    _handleChange({ openBackdrop: true });

    const result = await resources.deleteVideo(deleteID);
    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (result.success) {
      snackbarType = 0;
      snackbarContent = "Successfully deleted!";
    } else {
      snackbarType = 1;
      snackbarContent = result.descriptions;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setOpenDialog(false);
    setMainState({ page: page, hardReload: !hardReload });
  };

  const body = generateBody(
    mainState.body,
    handleEditVideos,
    handleDelete,
    classes
  );

  return (
    <div>
      {redirect && <Redirect to={redirect} push />}

      <Container className={classes.tableWrapper}>
        <CustomDeleteDialog
          open={openDialog}
          setOpen={setOpenDialog}
          name={mainState.deleteVideo}
          handleDelete={deleteVideo}
        />
        <Paper classes={{ root: classes.filterWrapper }}>
          <div
            className={clsx(
              classes.filterTitleWrapper,
              classes.flexCenter,
              classes.flexJustifyCenter
            )}
          >
            <Typography className={classes.title}>Videos</Typography>
          </div>

          <div className={clsx(classes.inputWrapper)}>
            <div
              className={clsx(
                classes.flexCenter,
                classes.flexJustifySpaceBetween
              )}
            >
              <CustomDatePicker
                label="Start Date"
                changeKey="startDate"
                date={mainState.startDate}
                handleDateChange={handleDateChange}
              />
              <CustomDatePicker
                label="End Date"
                changeKey="endDate"
                date={mainState.endDate}
                handleDateChange={handleDateChange}
              />

              <FormControl className={classes.inputWidth}>
                <InputLabel htmlFor="age-simple">Categories</InputLabel>
                <Select
                  value={mainState.categoryValue}
                  onChange={handleChange("categoryValue")}
                  inputProps={{
                    name: "category",
                    id: "select-category",
                  }}
                >
                  {mainState.categoryOption.map((e) => (
                    <MenuItem key={e.label} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              className={clsx(
                classes.flexCenter,
                classes.flexJustifySpaceBetween
              )}
            >
              <TextField
                id="search-text"
                label="Search"
                className={classes.inputWidth}
                value={mainState.searchValue}
                onChange={handleChange("searchValue")}
              />

              <TextField
                id="search-tag-text"
                label="Search Tag"
                className={classes.inputWidth}
                value={mainState.searchTagValue}
                onChange={handleChange("searchTagValue")}
              />

              <TextField
                id="search-model-text"
                label="Search Model"
                className={classes.inputWidth}
                value={mainState.searchModelValue}
                onChange={handleChange("searchModelValue")}
              />

              <TextField
                id="search-studio-text"
                label="Search Studio"
                className={classes.inputWidth}
                value={mainState.searchStudioValue}
                onChange={handleChange("searchStudioValue")}
              />
            </div>
          </div>

          <Button
            className={classes.searchButton}
            variant="contained"
            color="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Paper>

        <CustomTable
          headCells={header}
          rows={body}
          page={mainState.page}
          pageItems={mainState.pageItems}
          rowsPerPage={mainState.rowsPerPage}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </Container>
    </div>
  );
}

export default VideoList;
