import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles(theme => ({
  // wrapper
  dynamicInputWrapper: {
    padding: '20px 40px',
  },
  dynamicItem: {
    backgroundColor: theme.palette.background.section,
    boxShadow: '3px 4px 25px 0px rgba(199,199,199,1);',
    borderRadius: 10,
    position: 'relative',
    marginTop: 20,
    minWidth: 360
  },
  closeIconWrapper: {
    position: 'absolute',
    top: -8,
    right: -4
  },
  dynamicAdd: {
    position: 'relative',
    cursor: 'pointer',
    minHeight: 184,
  },
  videoPartWrapper: {
    backgroundColor: '#8e8e8e',
    borderRadius: 7,
    margin: 20,
    padding: 20,
  },
  // text
  textField: {
    width: '100%'
  },
  closeIcon: {
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  },
  addIcon: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    marginTop: -50,
    height: 100,
    width: '40px !important',
    left: '50%',
    marginLeft: -20
  },
  // common
  spacing: {
    marginTop: 20
  },
  widthSpacing: {
    marginLeft: 5,
    marginRight: 5
  },
  columnSplit: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  columnHalfWidth: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  columnWidth75: {
    width: '50%'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexAlignBottom: {
    alignItems: 'flex-end'
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifySpaceEvenly: {
    justifyContent: 'space-evenly'
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between'
  }
}));

function LinkArrBox(props) {
  const classes = useStyles();
  const { videos, defaultVideoValue, setMainState, sourceOption, sourceTypeOption } = props;

  const handleClose = (key, index) => () => {
    const newVideos = [...videos];
    newVideos[index][key] = false;
    setMainState({ videos: newVideos })
  };

  const handleOpen = (key, index) => () => {
    const newVideos = [...videos];
    newVideos[index][key] = true;
    setMainState({ videos: newVideos })
  };

  const handlesDynamicChange = (key, index) => event => {
    const newVideos = [...videos];
    newVideos[index][key] = event.target.value;
    setMainState({ videos: newVideos })
  };

  const handlePartArrChange = (sectionIndex, partIndex, key) => event => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr[partIndex][key] = event.target.value;
    setMainState({ videos: newVideos })
  };

  const handlePartDownloadArrChange = (sectionIndex, partIndex, index) => event => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr[partIndex].downloadLink[index] = event.target.value;
    setMainState({ videos: newVideos })
  };

  const handleAddSection = () => {
    const newVideos = [...videos];
    const newDefaultVideos = { linkArr: [{sourceType: '', partLink: '', downloadLink: ['']}], source: 'EMBED', length: '', videoSourceOpen: false};
    newVideos.push(newDefaultVideos);
    setMainState({ videos: newVideos })
  };

  const handleDeleteSection = index => () => {
    videos.splice(index, 1);
    setMainState({ videos })
  };

  const handleAddLink = (sectionIndex, index) => () => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr.splice(index + 1, 0, { sourceType: '', partLink: '', downloadLink: [''] });
    setMainState({ videos: newVideos })
  };

  const handleDeleteLink = (sectionIndex, index) => () => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr.splice(index, 1);
    setMainState({ videos: newVideos })
  };

  const handleAddDownloadLink = (sectionIndex, partIndex, index) => () => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr[partIndex].downloadLink.splice(index + 1, 0, '');
    setMainState({ videos: newVideos })
  };

  const handleDeleteDownloadLink = (sectionIndex, partIndex, index) => () => {
    const newVideos = [...videos];
    newVideos[sectionIndex].linkArr[partIndex].downloadLink.splice(index, 1);
    setMainState({ videos: newVideos })
  };

  return (
    <Grid
      container
      spacing={5}
      justify="space-between"
      className={clsx(classes.dynamicInputWrapper, classes.spacing)}
    >
      {videos.map((e, i) => (
        <Grid
          item
          xs={12}
          className={classes.dynamicItem}
          key={`source-${i}`}
        >
          {e.linkArr.map((l, k) => (
            <div key={k} className={classes.videoPartWrapper}>

              <FormControl className={clsx(classes.columnHalfWidth)}>
                <InputLabel id={`source-select-${i}`}>Video Source</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id={`part-source-controlled-open-select-${i}`}
                  value={l.sourceType}
                  onChange={handlePartArrChange(i, k, 'sourceType')}
                >
                  {sourceTypeOption.map(d => (
                    <MenuItem key={d.label} value={d.value}>{d.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className={clsx(classes.flexAlignBottom, classes.flexCenter)}>
                <Typography className={classes.widthSpacing}>Part {k}:</Typography>

                <TextField
                  id={`url-${i}-${k}`}
                  label="URL/Embed"
                  className={clsx(classes.columnWidth75, classes.widthSpacing)}
                  value={l.partLink}
                  onChange={handlePartArrChange(i, k, 'partLink')}
                />

                <div className={classes.flexCenter}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={clsx(classes.closeIcon, classes.widthSpacing)}
                    onClick={handleAddLink(i, k)}
                  />

                  {k !== 0 &&
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={clsx(classes.closeIcon, classes.widthSpacing)}
                      onClick={handleDeleteLink(i, k)}
                    />
                  }
                </div>
              </div>

              {l.downloadLink.map((m, n) => (
                <div
                  className={clsx(classes.flexAlignBottom, classes.flexCenter)}
                  key={n}
                >
                  <Typography className={classes.widthSpacing}>Download URL {n}:</Typography>
                  <TextField
                    id={`url-${k}-${n}-${m}`}
                    label="URL/Embed"
                    className={clsx(classes.columnWidth75, classes.widthSpacing)}
                    value={m}
                    onChange={handlePartDownloadArrChange(i, k, n)}
                  />

                  <div className={classes.flexCenter}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={clsx(classes.closeIcon, classes.widthSpacing)}
                      onClick={handleAddDownloadLink(i, k, n)}
                    />

                    {n !== 0 &&
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className={clsx(classes.closeIcon, classes.widthSpacing)}
                        onClick={handleDeleteDownloadLink(i, k, n)}
                      />
                    }
                  </div>
                </div>
              ))}

            </div>
          ))}

          <div className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween, classes.spacing)}>
            <FormControl className={clsx(classes.columnHalfWidth)}>
              <InputLabel id={`source-select-${i}`}>Resource Source</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id={`source-controlled-open-select-${i}`}
                open={e.videoSourceOpen}
                onClose={handleClose('videoSourceOpen', i)}
                onOpen={handleOpen('videoSourceOpen', i)}
                value={e.source}
                onChange={handlesDynamicChange('source', i)}
              >
                {sourceOption.map(e => (
                  <MenuItem key={e.label} value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*<TextField*/}
            {/*  id={`length-${i}`}*/}
            {/*  label="Video length"*/}
            {/*  className={classes.columnHalfWidth}*/}
            {/*  value={e.length}*/}
            {/*  onChange={handlesDynamicChange('length', i)}*/}
            {/*/>*/}
          </div>

          <span
            className={classes.closeIconWrapper}
            onClick={handleDeleteSection(i)}
          >
            <FontAwesomeIcon icon={faTimesCircle} className={classes.closeIcon}/>
          </span>
        </Grid>
      ))}

      <Grid
        item
        xs={12}
        className={clsx(classes.dynamicItem, classes.dynamicAdd)}
        onClick={handleAddSection}
      >
        <FontAwesomeIcon icon={faPlusCircle} className={classes.addIcon}/>
      </Grid>
    </Grid>
  )
}

export default LinkArrBox;
