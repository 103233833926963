import React from 'react';
import clsx from 'clsx';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import {Typography} from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 30,
    boxShadow: '0 3px 6px 0 rgba(131, 131, 131, 0.16)',
    padding: '10px 25px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 30,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  displayIncline: {
    display: 'flex',
  },
  selectWrapper: {
    flex: .7,
    // marginTop: 8,
  },
  fullWidth: {
    width: '100%',
  },
  selectLabel: {
  },
  fieldLabel: {
    // fontSize: theme.palette.primary.fontSize,
    // color: theme.palette.primary.contrastText,
    flex: .3,
  }
}));

export default function CustomSelect(props) {
  const { label, stateKey, disabled, incline, value, items, handleChange, fullWidth } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={clsx(classes.container, incline && classes.displayIncline)}>
        <Typography className={classes.fieldLabel}>{label}</Typography>
        <Select
          className={clsx(
            label && classes.selectWrapper,
            fullWidth && classes.fullWidth
          )}
          id={label}
          color="primary"
          disabled={disabled && !!disabled}
          value={value}
          onChange={handleChange(stateKey)}
          input={<BootstrapInput />}
        >
          {
            items.map((item, index) => (
              <MenuItem
                value={item.value}
                key={index + item.label}
              >
                {item.label}
              </MenuItem>
            ))
          }
        </Select>
      </div>
    </React.Fragment>
  );
}
