import fetchData from "../utils/fetchData";

function getStudio() {
  return fetchData({
    url: "/taxonomy/studio/all",
    method: "GET",
  });
}

function getCategories() {
  return fetchData({
    url: "/taxonomy/category/all",
    method: "GET",
  });
}

function getTags() {
  return fetchData({
    url: "/taxonomy/tags/all",
    method: "GET",
  });
}

function getModels() {
  return fetchData({
    url: "/taxonomy/models/all",
    method: "GET",
  });
}

function getVideoList(params) {
  return fetchData({
    url: "/video/panel",
    method: "GET",
    params: params,
  });
}

function getTheVideo(resourceID) {
  return fetchData({
    url: "/video/panel/" + resourceID,
    method: "GET",
  });
}

function updateVideo(data, resourceID) {
  return fetchData({
    url: "/video/panel/" + resourceID,
    method: "PUT",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function createVideo(formData) {
  return fetchData({
    url: "/video/panel",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function deleteVideo(resourceID) {
  return fetchData({
    url: "/video/panel/" + resourceID,
    method: "DELETE",
  });
}

function getAllVideoResources() {
  return fetchData({
    url: "/taxonomy/video-source-type/all",
    method: "GET",
  });
}

function getAllCrawlerData(params) {
  return fetchData({
    url: "/crawler-video",
    method: "GET",
    params: params,
  });
}

function getTheCrawlerData(id) {
  return fetchData({
    url: "/crawler-video/" + id,
    method: "GET",
  });
}

function updateTheCrawlerData(id, body) {
  return fetchData({
    url: "/crawler-video/" + id,
    method: "PUT",
    data: body,
  });
}

function deleteTheCrawlerData(id) {
  return fetchData({
    url: "/crawler-video/" + id,
    method: "DELETE",
  });
}

function batchInsertCrawlerData(body) {
  return fetchData({
    url: "/crawler-video/bulk-insert",
    method: "POST",
    data: body,
  });
}

function getPrefillList(params) {
  return fetchData({
    url: "/prefill",
    method: "GET",
    params: params,
  });
}

function createPrefill(body) {
  return fetchData({
    url: "/prefill",
    method: "POST",
    data: body,
  });
}

function updatePrefill(body, resourceID) {
  return fetchData({
    url: "/prefill/" + resourceID,
    method: "PUT",
    data: body,
  });
}

function deletePrefill(resourceID) {
  return fetchData({
    url: "/prefill/" + resourceID,
    method: "DELETE",
  });
}

function bulkDeletePrefill(items) {
  return fetchData({
    url: "/videos/crawler/prefill",
    method: "DELETE",
    data: items,
  });
}

function getCrawlerManagerVideos(params) {
  return fetchData({
    url: "/crawler-sync",
    method: "GET",
    params: params,
  });
}

function getTheCrawlerManagerVideo(ID) {
  return fetchData({
    url: `/crawler-sync/details/${ID}`,
    method: "GET",
  });
}

function cloneVideoURL() {
  return fetchData({
    url: `/crawler-sync/clone-url`,
    method: "POST",
  });
}

function syncPlatform(body) {
  return fetchData({
    url: `/crawler-sync/upload-third-party`,
    method: "POST",
    data: body,
  });
}

function updateToVideo(body) {
  return fetchData({
    url: `/crawler-sync/bulk-update`,
    method: "PUT",
    data: body,
  });
}

function syncLocal(body) {
  return fetchData({
    url: `/crawler-sync/sync`,
    method: "POST",
    data: body,
  });
}

function bulkDelete(body) {
  return fetchData({
    url: `/crawler-sync`,
    method: "DELETE",
    data: body,
  });
}

export default {
  getStudio,
  getCategories,
  getTags,
  getModels,
  getVideoList,
  getTheVideo,
  updateVideo,
  createVideo,
  deleteVideo,
  getAllVideoResources,
  // Crawler
  getAllCrawlerData,
  getTheCrawlerData,
  updateTheCrawlerData,
  deleteTheCrawlerData,
  batchInsertCrawlerData,
  getPrefillList,
  createPrefill,
  updatePrefill,
  deletePrefill,
  bulkDeletePrefill,
  // Crawler Manager
  getCrawlerManagerVideos,
  getTheCrawlerManagerVideo,
  cloneVideoURL,
  syncPlatform,
  updateToVideo,
  syncLocal,
  bulkDelete,
};
