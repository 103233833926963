import React, { useState, useContext, useEffect } from 'react';
import {Redirect} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { utilsContext } from '../contexts/utils';

// component
import fetchData from '../utils/fetchData';
import useQuery from "../hooks/useQuery";

// material ui
import {
  TextField,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton
} from "@material-ui/core";

// icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles(theme => ({
  spacing: {
    margin: 10
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  sections: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 300,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 30,
    paddingBottom: 30,
    minHeight: 300,
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.contrastText
  },
  inputFlied: {
    width: '50%',
    minWidth: 200
  },
  submitButton: {
    width: '50%',
    minWidth: 200
  }
}));


function Login() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [query, setQuery] = useQuery();
  const [redirect, setRedirect] = useState(false);
  const [mainState, _setMainState] = useState({
    username: '',
    password: '',
    showPassword: false
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    let rejectReason = query.reason;
    if (rejectReason) {
      _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: rejectReason,
      });
    }

  }, []);



  const handleChange = prop => event => {
    setMainState({ [prop]: event.target.value });
  };

  const handleToggle = prop => event => {
    setMainState({ [prop]: !mainState[prop] });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  const keyPress = (e) => {
    if(e.keyCode === 13) login();
  };

  const login = async () => {
    const { username, password } = mainState;
    _handleChange({openBackdrop: true});

    const options = {
      url: '/auth/login',
      data: { username, password, rememberAcc: true },
      method: 'POST',
    };
    const result = await fetchData(options);
    const { success, data } = result;

    let snackbarType, snackbarContent;
    if (success) {
      const userName = data.name;
      snackbarType = 0;
      snackbarContent = 'Welcome back ' + userName;
      localStorage.setItem("userName", userName);
      setRedirect(true)
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({
      openBackdrop: false,
      snackbar: true,
      snackbarType,
      snackbarContent
    });
  };

  return (
    <div className={classes.wrapper}>

      {redirect && <Redirect to="/admin/dashboard" push/>}

      <h1 className={classes.title}>OPV Project</h1>
      <Paper className={classes.sections}>
        <TextField
          required
          id="name"
          className={clsx(classes.inputFlied, classes.spacing)}
          label="Email"
          onChange={handleChange('username')}
          onKeyDown={keyPress}
        />
        <FormControl className={clsx(classes.spacing, classes.inputFlied)}>
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={mainState.showPassword ? 'text' : 'password'}
            value={mainState.password}
            onChange={handleChange('password')}
            onKeyDown={keyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggle('showPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {mainState.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.submitButton, classes.spacing)}
          onClick={login}
          disableElevation
        >
          Login
        </Button>
      </Paper>
    </div>
  )
}

export default Login;
