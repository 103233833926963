import fetchData from '../utils/fetchData';


function getDashboard() {
  return fetchData({
    url: '/generals/dashboard',
    method: 'GET'
  });
}

function temSyncVideo() {
  return fetchData({
    url: 'https://api.jpvhub.com/api/video/daily/clone',
    method: 'GET',
  })
}

export default {
  getDashboard,
  temSyncVideo,
}
