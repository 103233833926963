import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default function CustomDatePicker(props) {
  const { label="Date picker", changeKey, date, handleDateChange} = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/DD/YYYY"
        margin="normal"
        id={`${changeKey}-date-picker`}
        label={label}
        value={date}
        onChange={handleDateChange(changeKey)}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>

  )
}
