import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Checkbox, FormControlLabel} from "@material-ui/core";

const CustomColor = withStyles(theme => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default function CustomCheckBox(props) {
  const { label="Date picker", value, changeKey, handleToggle} = props;

  return (
    <FormControlLabel
      control={<CustomColor checked={value} onChange={handleToggle(changeKey)} name={label} />}
      label={label}
    />
  )
}
