import React from 'react';

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CustomSelect from "../../components/CustomSelect";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    minWidth: 700,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    }
  },
  buttonWrapper: {
    textAlign: 'end',
    width: '100%',
    margin: '10px 0 30px',
  },
}))


function FormDialog(props) {
  const {open, setOpen, items, setMainState, categories, handleSubmit} = props;
  const classes = useStyles();
  const typeTitle = items.type === 0 ? 'Create' : 'Update';

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = key => event => {
    const newObj = {...items};
    newObj[key] = event.target.value;
    setMainState({ dialogItems: newObj });
  }


  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">
        {typeTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogWrapper}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          className={classes.inputWrapper}
        >
          <Grid item xs={12}>
            <CustomSelect
              fullWidth
              label="Category"
              stateKey="category"
              value={items.category}
              items={categories}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="name"
              label="Chinese Name"
              fullWidth
              value={items.name}
              onChange={handleChange('name')}
            />
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*  <TextField*/}
          {/*    id="videoSource"*/}
          {/*    label="Video Source"*/}
          {/*    fullWidth*/}
          {/*    value={items.videoSource}*/}
          {/*    onChange={handleChange('videoSource')}*/}
          {/*  />*/}
          {/*</Grid>*/}

          <div className={classes.buttonWrapper}>
            <Button onClick={handleSubmit} color="primary">
              {typeTitle}
            </Button>
          </div>

        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default FormDialog;
