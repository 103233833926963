import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import FilterListIcon from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align='left'
            padding='default'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.disabledSort ? () => {} : createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    boxShadow: '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px #4baf506b',
    backgroundColor: theme.palette.primary.main,
    margin: '0 15px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        Nutrition
      </Typography>

      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EnhancedTableBody = props => {
  const { rows, page, rowsPerPage, emptyRows } = props;

  return (
    <TableBody>
      {rows
        .map((row, index) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.name + index + row.id}
            >
              {Object.keys(row).map((key, index) => (
                <TableCell key={`${row.name}-${index}`} align="left">{row[key]}</TableCell>
              ))}
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 480
    },
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    padding: 15,
    textAlign: 'end',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageWrapper: {
    paddingBottom: 10,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const {
    headCells, rows, page, pageItems, rowsPerPage, order, orderBy, setState,
    search, setSearch
  } = props;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  };

  const handleSearch = () => {
    setState({ searchValue: search })
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    let newOrder = isAsc ? 'desc' : 'asc';

    setState({ order: newOrder, orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setState({ page: newPage })
  };

  const handleChangeRowsPerPage = event => {
    setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/*<EnhancedTableToolbar numSelected={0}/>*/}
        <TableContainer classes={{ root: classes.tableWrapper }}>
          {setSearch &&
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">Search Name</InputLabel>
              <Input
                id="search-box"
                type="text"
                value={search}
                onChange={handleSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={handleSearch}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          }
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <EnhancedTableBody
              rows={rows}
              headCells={headCells}
              page={page}
              rowsPerPage={rowsPerPage}
              // emptyRows={emptyRows}
            />
          </Table>
        </TableContainer>
        {(pageItems > rowsPerPage) &&
          <div className={classes.pageWrapper}>
            <Pagination
              count={ Math.ceil(pageItems/rowsPerPage)}
              variant="outlined"
              color="primary"
              page={page}
              onChange={handleChangePage}
            />
          </div>
        }

        {/*<TablePagination*/}
        {/*  rowsPerPageOptions={[]}*/}
        {/*  component="div"*/}
        {/*  count={pageItems}*/}
        {/*  rowsPerPage={rowsPerPage}*/}
        {/*  page={page}*/}
        {/*  onChangePage={handleChangePage}*/}
        {/*  // onChangeRowsPerPage={handleChangeRowsPerPage}*/}
        {/*/>*/}
      </Paper>
    </div>
  );
}
