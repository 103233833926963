import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// material UI
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
// react-beautiful-dnd
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// component
import CustomTable from "../../../components/CustomTable";
import CustomDeleteDialog from "../../../components/CustomDeleteDialog";
import { utilsContext } from "../../../contexts/utils";

import taxonomic from "../../../api/taxonomic";
import contents from "../../../api/contents";

const useStyles = makeStyles((theme) => ({
  special: {
    color: "red",
    fontSize: 30,
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary,
  },
  tableWrapper: {
    padding: 0,
  },
  sections: {
    backgroundColor: "yellow",
  },
  filterWrapper: {
    display: "grid",
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10,
  },
  inputWrapper: {
    padding: theme.spacing(2),
  },
  inputWidth: {
    width: "100%",
    marginBottom: 10,
  },
  // text
  title: {
    fontSize: 21,
    color: theme.palette.secondary.main,
  },
  // component
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: "100%",
  },
  urlLink: {
    color: "grey",
    textDecoration: "underline",

    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  // common
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

const header = [
  { id: "id", label: "ID", disabledSort: true },
  { id: "name", label: "Name" },
  { id: "createdAt", label: "Created At" },
  { id: "action", label: "Action", disabledSort: true },
];

const generateBody = (
  items,
  page,
  rowsPerPage,
  handlePushUrl,
  handleChangeTagList,
  handleUpdate,
  handleDelete,
  classes
) => {
  return items.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    name: (
      <TextField value={e.name} onChange={handleChangeTagList("name", i)} />
    ),
    createdAt: e.createdAt,
    action: (
      <div
        className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween)}
      >
        <Button
          variant="contained"
          color="secondary"
          className={classes.updateButton}
          onClick={handleUpdate(e)}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled
          className={classes.deleteButton}
          onClick={handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    ),
  }));
};

function VideoResources(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [bodyData, setBodyData] = useState([]);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 15,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    startDate: null,
    endDate: null,
    body: [],
    name: "",
    searchValue: "",
    deleteID: "",
    deleteName: "",
  });

  useEffect(() => {
    async function resourceList() {
      _handleChange({ openBackdrop: true });
      const { page, rowsPerPage, searchValue, order, orderBy } = mainState;

      const { success, data, descriptions } = await contents.getVideoResources({
        page: page,
        limit: rowsPerPage,
        keyword: searchValue,
        sort: orderBy,
        sortBy: order,
      });

      _handleChange({ openBackdrop: false });

      if (!success) {
        return _handleChange({
          snackbar: true,
          snackbarType: 1,
          snackbarContent: descriptions,
        });
      }

      setBodyData(data.list);
      setMainState({ pageItems: data.total });
    }

    resourceList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.searchValue,
    mainState.refresh,
  ]);

  // common functions
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleChangeTagList = (key, index) => (event) => {
    let newBodyData = [...bodyData];
    newBodyData[index][key] = event.target.value;
    setBodyData(newBodyData);
  };

  const keyPress = (e) => {
    const { name } = mainState;

    if (name) {
      if (e.keyCode === 13) createResources();
    }
  };

  const handlePushUrl = (name) => () => {
    props.history.push(`/videos?searchTag=${name}`);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(bodyData);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    // Update the order value for each item based on its new index
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      order: index + 1, // Adding 1 to make order start from 1 instead of 0
    }));

    setBodyData(updatedItems);
  };

  const handleNameChange = (id, newName) => {
    setBodyData(
      bodyData.map((item) =>
        item.ID === id ? { ...item, name: newName } : item
      )
    );
  };

  // API functions
  const handleUpdateAll = async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await contents.bulkUpdateVideoResources({
      list: bodyData,
    });

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully Updated!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
  };

  const handleUpdate = (details) => async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await contents.updateVideoResources(
      details.ID,
      details
    );

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully Updated!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
  };

  const handleDelete = (details) => async () => {
    setMainState({ deleteID: details.ID, deleteName: details.name });
    setOpenDialog(true);
  };

  const createResources = async () => {
    const { name } = mainState;

    try {
      if (!name) throw "Name cannot be blank";
    } catch (err) {
      return _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: err,
      });
    }

    _handleChange({ openBackdrop: true });
    const { success, data } = await contents.createVideoResources({ name });
    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully created!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setMainState({ name: "", page: 1, refresh: !mainState.refresh });
  };

  const deleteResources = async () => {
    const { deleteID } = mainState;
    _handleChange({ openBackdrop: true });

    const { success, data } = await contents.deleteVideoResources(deleteID);

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully deleted!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setOpenDialog(false);
    setMainState({ page: 1, refresh: !mainState.refresh });
  };

  // const body = generateBody(bodyData, mainState.page, mainState.rowsPerPage, handlePushUrl, handleChangeTagList, handleUpdate, handleDelete, classes);

  return (
    <div>
      <Container className={classes.tableWrapper}>
        <CustomDeleteDialog
          open={openDialog}
          setOpen={setOpenDialog}
          name={mainState.deleteName}
          handleDelete={deleteResources}
        />
        <Paper classes={{ root: classes.filterWrapper }}>
          <div
            className={clsx(
              classes.filterTitleWrapper,
              classes.flexCenter,
              classes.flexJustifyCenter
            )}
          >
            <Typography className={classes.title}>Resources Type</Typography>
          </div>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            className={classes.inputWrapper}
          >
            <Grid item xs={12} sm={8}>
              <TextField
                id="tag-text"
                label="Name"
                className={classes.inputWidth}
                value={mainState.name}
                onChange={handleChange("name")}
                onKeyDown={keyPress}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                className={classes.addButton}
                variant="contained"
                color="primary"
                disabled
                onClick={createResources}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Paper classes={{ root: classes.filterWrapper }}>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={handleUpdateAll}
          >
            Update
          </Button>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {bodyData.map((item, index) => (
                    <Draggable
                      key={item.ID}
                      draggableId={item.ID}
                      index={index}
                    >
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <ListItemSecondaryAction
                            {...provided.dragHandleProps}
                          >
                            <IconButton edge="end" aria-label="drag">
                              <DragIndicatorIcon />
                            </IconButton>
                          </ListItemSecondaryAction>

                          <TextField
                            value={item.name}
                            onChange={(e) =>
                              handleNameChange(item.ID, e.target.value)
                            }
                          />
                          <ListItemText secondary={`Order: ${item.order}`} />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
        {/* <CustomTable
          search={search}
          setSearch={setSearch}
          headCells={header}
          rows={body}
          page={mainState.page}
          pageItems={mainState.pageItems}
          rowsPerPage={mainState.rowsPerPage}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        /> */}
      </Container>
    </div>
  );
}

export default VideoResources;
