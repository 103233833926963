import { createContext } from 'react'

const defaultState = {
  theme: '#009688',
  language: '',
  snackbar: false,
  snackbarType: 0,
  snackbarContent: 'Success',
  openBackdrop: false
};

const utilsContext = createContext(defaultState);

export { defaultState, utilsContext }
