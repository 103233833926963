import React from 'react';

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid, IconButton, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import StatusIcon from "./StatusIcon";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    minWidth: 700,
    paddingBottom: 80,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    }
  },
  inputWrapper: {
  },
  statusFont: {
    textAlign: 'center',
  }
}));

function EditDialog(props) {
  const {open, setOpen, items, setMainState, handleSubmit} = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = key => event => {
    const newObj = {...items};
    newObj[key] = event.target.value;
    setMainState({ dialogItems: newObj });
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Edit
      </DialogTitle>
      <DialogContent className={classes.dialogWrapper}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          className={classes.inputWrapper}
        >
          <Grid item xs={12} md={8}>
            <TextField
              id="name"
              label="Video Name"
              disabled
              fullWidth
              value={items.name}
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={classes.statusFont}>Status: <b>{items.status ? 'Synced' : 'Not Sync'}</b> </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="originalURL"
              label="Original Remote URL"
              disabled
              fullWidth
              value={items.originalURL}
              onChange={handleChange('originalURL')}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              <Grid item xs={11}>
                <TextField
                  id="streamtape"
                  label="StreamTape"
                  disabled
                  fullWidth
                  value={items.streamtape}
                  onChange={handleChange('streamtape')}
                />
              </Grid>
              <Grid item xs={1}>
                <StatusIcon status={items.streamtapeStatus} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              <Grid item xs={11}>
                <TextField
                  id="turbo"
                  label="Turbo"
                  disabled
                  fullWidth
                  value={items.turbo}
                  onChange={handleChange('turbo')}
                />
              </Grid>
              <Grid item xs={1}>
                <StatusIcon status={items.turboStatus} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              <Grid item xs={11}>
                <TextField
                  id="doodstream"
                  label="DoodStream"
                  disabled
                  fullWidth
                  value={items.doodstream}
                  onChange={handleChange('doodstream')}
                />
              </Grid>
              <Grid item xs={1}>
                <StatusIcon status={items.doodstreamStatus} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              <Grid item xs={11}>
                <TextField
                  id="streamwish"
                  label="StreamWish"
                  disabled
                  fullWidth
                  value={items.streamwish}
                  onChange={handleChange('streamwish')}
                />
              </Grid>
              <Grid item xs={1}>
                <StatusIcon status={items.streamwishStatus} />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {/*<Button onClick={handleSubmit} color="primary">*/}
        {/*  Update*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog;
