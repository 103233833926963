import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

// material UI
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  InputLabel, Select, MenuItem, FormControl
} from "@material-ui/core";

// component
import {utilsContext} from "../../../contexts/utils";
import contents from "../../../api/contents";

const footerOption = [
  { value: 'FOOTER', label: 'English Footer' },
  { value: 'CN_FOOTER', label: 'Chinese Footer' },
  { value: 'JP_FOOTER', label: 'Japanese Footer' },
];

const useStyles = makeStyles(theme => ({
  special: {
    color: 'red',
    fontSize: 30
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary
  },
  tableWrapper: {
    padding: 0
  },
  sections: {
    backgroundColor: 'yellow'
  },
  filterWrapper: {
    display: 'grid',
    marginBottom: 40
  },
  filterTitleWrapper: {
    margin: '-25px 15px 0',
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10
  },
  inputWrapper: {
    padding: theme.spacing(2),
  },
  inputWidth: {
    width: '100%',
    marginBottom: 10,
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main
  },
  notice: {
    color: '#9E9E9E',
    fontSize: 14,
  },
  // component
  deleteButton: {
    backgroundColor: '#f75145',
    color: theme.palette.primary.contrastText
  },
  addButton: {
    width: '100%',
  },
  // common
  columnHalfWidth: {
    width: '50%',
  },
  spacing: {
    marginTop: 10,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifySpaceEvenly: {
    justifyContent: 'space-evenly'
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between'
  }
}));

function Footers() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [language, setLanguage] = useState('FOOTER');
  const [mainState, _setMainState] = useState({
    title: '',
    subTitle: '',
    description: '',
    meta: '',
    languageOpen: false,
  });

  useEffect(() => {
    async function getData() {
      _handleChange({ openBackdrop: true });
      const { success, data } = await contents.getFooter({ language });
      _handleChange({ openBackdrop: false });

      if (!success) {
        return _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: data.message });
      }
      setMainState({
        title: data.title || '',
        subTitle: data.sub_title || '',
        description: data.description || '',
        meta: data.meta || '',
      });
    }

    getData();
  }, [language]);


  // common functions
  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = key => event => {
    setMainState({ [key]: event.target.value })
  };

  const handleClose = key => () => {
    setMainState({ [key]: false })
  };

  const handleOpen = key => () => {
    setMainState({ [key]: true })
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) handleUpdate();
  };

  const handleLanguageChange = event => {
    setLanguage(event.target.value)
  };

  // API functions
  const handleUpdate = async () => {
    const { title, subTitle, description, meta } = mainState;

    try {
      if (!title) throw 'Title';
      if (!subTitle) throw 'Sub title';
      if (!description) throw 'Description';
      if (!meta) throw 'Meta';
    } catch (err) {
      return _handleChange({ snackbar: true, snackbarType: 2, snackbarContent: err + ' cannot be empty' });
    }

    _handleChange({ openBackdrop: true });
    const { success, data } = await contents.updateFooter({
      title: title,
      sub_title: subTitle,
      description: description,
      meta: meta,
      language: language,
    });
    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = 'Successfully updated!'
    } else {
      snackbarType = 1;
      snackbarContent = data.message
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
  };

  return (
    <div>
      <Container className={classes.tableWrapper}>
        <Paper classes={{ root: classes.filterWrapper }}>
          <div className={clsx(classes.filterTitleWrapper, classes.flexCenter, classes.flexJustifyCenter)}>
            <Typography className={classes.title}>Footers</Typography>
          </div>

          <div className={classes.inputWrapper}>
            <FormControl className={classes.columnHalfWidth}>
              <InputLabel id="category-option-select">Language</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={mainState.languageOpen}
                onClose={handleClose('languageOpen')}
                onOpen={handleOpen('languageOpen')}
                value={language}
                onChange={handleLanguageChange}
              >
                {footerOption.map(e => (
                  <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="title-text"
              label="Title"
              className={clsx(classes.inputWidth, classes.spacing)}
              value={mainState.title}
              onChange={handleChange('title')}
              onKeyDown={keyPress}
            />
            <TextField
              id="sub-title"
              label="Sub title"
              multiline
              rowsMax="4"
              className={clsx(classes.inputWidth, classes.spacing)}
              value={mainState.subTitle}
              onChange={handleChange('subTitle')}
            />
            <Typography className={classes.notice}> * Please us comma(,) to divided content. Example: 100% Free video,
              Captured in HD quality, ...</Typography>
            <TextField
              id="description"
              label="Description"
              multiline
              rowsMax="4"
              className={clsx(classes.inputWidth, classes.spacing)}
              value={mainState.description}
              onChange={handleChange('description')}
            />
            <TextField
              id="meta-text"
              label="Meta"
              className={clsx(classes.inputWidth, classes.spacing)}
              value={mainState.meta}
              onChange={handleChange('meta')}
              onKeyDown={keyPress}
            />
          </div>

          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>

        </Paper>
      </Container>
    </div>
  )
}

export default Footers;
