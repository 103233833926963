import React, {useState} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
// context
import {defaultState, utilsContext} from './contexts/utils';

// component
import Router from './Router';
import Backdrop from './components/Backdrop';
import Snackbar from './components/Snackbar';

// schema theme, font, ... at here
const theme = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        borderRadius: 10,
        border: `1px solid green`,
        backgroundColor: 'red',
      },
      multiline: {
        '&$marginDense': {
          paddingTop: 6,
        }
      },
    },
    MuiOutlinedInput: {
      root: {

      }
    }
  },
  typography: {
    fontFamily: 'GillSans',
  },
  shadows: Array(25).fill('none'),
  palette: {
    primary: {
      main: '#4baf50',
      contrastText: '#fff',
      fontSize: 25
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#fff',
      fontSize: 18
    },
    background: {
      default: '#212226',
      section: '#b1b1b1',
      navBar: '#ffffff',
    },
  },
});

function App() {
  // add context provider at here
  const [utilsContextState, setUtilsContext] = useState(defaultState);

  const handleUtilsContextChange = (newState) => {
    setUtilsContext((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  return (
    <utilsContext.Provider
      value={{
        ...utilsContextState,
        _handleChange: handleUtilsContextChange
      }}
    >
      <MuiThemeProvider theme={theme}>
        <Backdrop
          open={utilsContextState.openBackdrop}
          handleOpen={handleUtilsContextChange}
        />
        <Snackbar
          open={utilsContextState.snackbar}
          type={utilsContextState.snackbarType}
          content={utilsContextState.snackbarContent}
          handleOpen={handleUtilsContextChange}
        />
        <Router />
      </MuiThemeProvider>
    </utilsContext.Provider>
  );
}

export default App;
