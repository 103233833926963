import React, { useContext, useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
// material UI
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  Checkbox,
} from "@material-ui/core";
// component
import CustomTable from "../../components/CustomTable";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect.js";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import CustomInsertDialog from "../../components/CustomInsertDialog";
import CustomInsertSelectedDialog from "../../components/CustomInsertSelectedDialog";
import EditFormDialog from "./EditFormDialog";
import useQuery from "../../hooks/useQuery";
import { utilsContext } from "../../contexts/utils";
import resources from "../../api/resources.js";

const getPlatformName = (type) => {
  let name;

  switch (type) {
    case 1:
      name = "Mosaic-Removed";
      break;
    case 2:
      name = "Censored";
      break;
    case 3:
      name = "ChineseSub";
      break;
    case 4:
      name = "85tube";
      break;
    case 5:
      name = "Uncensored";
      break;
    case 6:
      name = "EnglishSub";
      break;
    case 7:
      name = "MosaicCN-Sub";
      break;
    default:
      name = "Mosaic-Removed";
  }

  return name;
};

const sortVideoType = (data) => {
  return {
    asianclub: data.asianclub,
    streamtape: data.streamtape,
    mixdrop: data.mixdrop,
    fileone: data.fileone,
    vidoza: data.vidoza,
  };
};

const generateBody = (
  items,
  handleCheck,
  handleEditVideos,
  handleDelete,
  classes
) => {
  return items.map((e, i) => ({
    check: (
      <Checkbox color="primary" onChange={handleCheck(i)} checked={e.checked} />
    ),
    name: <Typography className={classes.videoName}>{e.name}</Typography>,
    type: getPlatformName(e.type),
    embedLink: (
      <div className={classes.statusWrapper}>
        <span
          className={clsx(classes.dotStatus, e.embedLink && classes.dotSuccess)}
        />
      </div>
    ),
    thumbnail: (
      <div className={classes.statusWrapper}>
        <span
          className={clsx(classes.dotStatus, e.thumbnail && classes.dotSuccess)}
        />
      </div>
    ),
    models: (
      <div className={classes.statusWrapper}>
        <span
          className={clsx(classes.dotStatus, e.model && classes.dotSuccess)}
        />
      </div>
    ),
    tags: (
      <div className={classes.statusWrapper}>
        <span
          className={clsx(classes.dotStatus, e.tags && classes.dotSuccess)}
        />
      </div>
    ),
    // downloadLink: <div className={classes.statusWrapper}><span className={clsx(classes.dotStatus, e.downloadLink && classes.dotSuccess)} /></div>,
    trailerStatus: (
      <div className={classes.statusWrapper}>
        <span
          className={clsx(classes.dotStatus, e.trailer && classes.dotSuccess)}
        />
      </div>
    ),
    createdAt: e.createdAt,
    action: (
      <div className={classes.actionWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditVideos(e.ID)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.deleteButton}
          onClick={handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    ),
  }));
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.secondary,
  },
  tableWrapper: {
    padding: 0,
  },
  tableInnerWrapper: {
    padding: 0,
    maxWidth: 1440,
    [theme.breakpoints.down(1300)]: {
      maxWidth: 980,
    },
    [theme.breakpoints.between(1300, 1445)]: {
      maxWidth: 1145,
    },
  },
  filterWrapper: {
    display: "grid",
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 200,
    borderRadius: 10,
  },
  inputWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  insertButtonGroupWrapper: {
    textAlign: "end",
    margin: "30px 0",
    left: "25%",
  },
  actionWrapper: {
    display: "flex",
  },
  statusWrapper: {
    position: "sticky",
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
  },
  videoName: {
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  insertButton: {
    marginLeft: 20,
  },
  searchButton: {
    borderRadius: "0 0 5px 5px",
    width: "100%",
    marginTop: 30,
  },
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  dotStatus: {
    position: "absolute",
    width: 15,
    height: 15,
    borderRadius: 50,
    backgroundColor: "red",
  },
  dotSuccess: {
    backgroundColor: "green",
  },
  // common
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

function CrawlerResources() {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openInsertDialog, setOpenInsertDialog] = useState(false);
  const [openSelectInsertDialog, setOpenSelectInsertDialog] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    pageItems: 15,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    checkAll: false,
    startDate: moment().subtract(3, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    type: 0,
    typeArr: [
      { label: "Please Select", value: 0 },
      { label: "Mosaic-Removed", value: 1 },
      { label: "Censored", value: 2 },
      { label: "Chinese Sub", value: 3 },
      { label: "Uncensored", value: 5 },
      { label: "English Sub", value: 6 },
      { label: "MosaicCN-Sub", value: 7 },
      // {label: '85tube', value: 4},
    ],
    videoResourceTypeOption: [{ value: "", label: "None" }],
    body: [],
    editDialogID: "",
    getCount: 0,
    insertCount: 0,
    hardReload: false,
    editItems: {
      title: { cn: "", en: "", jp: "" },
      downloadLink: [
        { asianclub: "", fileone: "", mixdrop: "", streamtape: "", vidoza: "" },
      ],
      embedLink: [
        [
          {
            sourceID: "",
            value: "",
            downloadLink: [""],
          },
        ],
      ],
      imgSrc: "",
      models: [{ cn: "", en: "", jp: "" }],
      studios: [{ cn: "", en: "", jp: "" }],
      tags: [{ cn: "", en: "", jp: "" }],
      trailer: "",
      videoDuration: "",
      videoType: 0,
    },
  });

  // common functions
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleDateChange = (key) => (event) => {
    setMainState({ [key]: event });
  };

  const handleDynamicCheck = (index) => (event) => {
    const tmpBody = [...mainState.body];
    tmpBody[index].checked = event.target.checked;
    setMainState({ body: tmpBody, checkAll: false });
  };

  const handleCheckAll = (event) => {
    const tmpBody = [...mainState.body];
    tmpBody.forEach((e) => {
      e.checked = event.target.checked;
    });
    setMainState({ body: tmpBody, checkAll: event.target.checked });
  };

  const handleSearch = async () => {
    await getVideoList(1);
  };

  const handleEditVideos = (id) => async () => {
    await getVideoDetails(id);
    setOpenEditDialog(true);
  };

  const handleDelete = (details) => async () => {
    setMainState({ deleteID: details.ID, deleteName: details.name });
    setOpenDialog(true);
  };

  const handleInsertAlertBox = () => {
    setOpenInsertDialog(true);
  };

  const handleInsertSelectedAlertBox = () => {
    const { body } = mainState;
    const checkedItems = body.filter((e) => e.checked);
    const idArray = checkedItems.map((e) => e.ID);

    if (!idArray.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }
    setMainState({ insertCount: idArray.length });
    setOpenSelectInsertDialog(true);
  };

  // API
  const getVideoList = async (searchPage) => {
    _handleChange({ openBackdrop: true });

    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      startDate,
      endDate,
      type,
      getCount,
    } = mainState;
    const params = {
      page: searchPage || page,
      limit: rowsPerPage,
      startDate: startDate && moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate && moment(endDate).format("YYYY-MM-DD"),
      sort: orderBy,
      sortBy: order,
      videoType: type === 0 ? null : type,
    };
    const { success, data } = await resources.getAllCrawlerData(params);
    const failureObj = {};

    if (success) {
      const tmpArr = data.videoList.map((e) => ({
        ID: e.ID,
        name: e.title.en && e.title.en !== "-" ? e.title.en : e.title.jp,
        type: e.videoType,
        embedLink: e.embedLink,
        thumbnail: e.imgSrc,
        tags: e.tags,
        model: e.models,
        downloadLink: e.downloadLink,
        trailer: e.trailer,
        createdAt: moment(e.createdAt).format("YYYY-MM-DD"),
        checked: false,
      }));

      setMainState({ body: tmpArr, pageItems: data.totals });
    } else {
      failureObj.snackbar = true;
      failureObj.snackbarType = 1;
      failureObj.snackbarContent = "Fail to get list";
    }

    if (getCount !== 0) setQuery(params);
    _handleChange({ openBackdrop: false, ...failureObj });
  };

  const getVideoResourceType = async () => {
    const result = await resources.getAllVideoResources();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get video resource",
      });
    }

    setMainState({
      videoResourceTypeOption: data.map((e) => ({
        value: e.ID,
        label: e.name,
      })),
    });
  };

  const getVideoDetails = async (id) => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await resources.getTheCrawlerData(id);
    const failureObj = {};

    if (success) {
      if (
        (!data.embedLink && !data.embedLink.length) ||
        (data.embedLink.length && data.embedLink[0] === "")
      ) {
        data.embedLink = [[{ sourceID: "", value: "", downloadLink: [""] }]];
      }
      setMainState({ editItems: data });
    } else {
      failureObj.snackbar = true;
      failureObj.snackbarType = 1;
      failureObj.snackbarContent = "Fail to get the details";
    }

    _handleChange({ openBackdrop: false, ...failureObj });
  };

  const handleUpdateDetails = async () => {
    const { editItems, hardReload } = mainState;
    _handleChange({ openBackdrop: true });

    console.log("editItems", editItems);
    let checkEmptyResource = [];

    if (editItems.embedLink.length === 1) {
      for (const item of editItems.embedLink[0]) {
        if (
          item.downloadLink.length === 1 &&
          item.sourceID === "" &&
          item.value === ""
        ) {
          checkEmptyResource.push(true);
        } else {
          checkEmptyResource.push(false);
        }
      }
    }

    const allEmpty = checkEmptyResource.every((item) => item === true);

    const snackbarObj = {};
    const { success } = await resources.updateTheCrawlerData(editItems.ID, {
      ...editItems,
      embedLink:
        allEmpty && editItems.embedLink.length === 1 ? "" : editItems.embedLink,
    });

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Success";
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Update";
    }

    _handleChange({ openBackdrop: false, ...snackbarObj });
    setOpenEditDialog(false);
    setMainState({ hardReload: !hardReload });
  };

  const deleteResources = async () => {
    const { deleteID, hardReload } = mainState;
    _handleChange({ openBackdrop: true });

    const snackbarObj = {};
    const { success } = await resources.deleteTheCrawlerData(deleteID);

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Success";
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Update";
    }

    _handleChange({ openBackdrop: false, ...snackbarObj });
    setOpenDialog(false);
    setMainState({ hardReload: !hardReload });
  };

  const bulkInsertData = async () => {
    const { hardReload } = mainState;
    _handleChange({ openBackdrop: true });
    setOpenInsertDialog(false);

    const snackbarObj = {};
    const { success } = await resources.batchInsertCrawlerData();

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Success";
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Fail to insert data";
    }

    _handleChange({ openBackdrop: false, ...snackbarObj });
    setMainState({ hardReload: !hardReload });
  };

  const insertSelectedData = async () => {
    const { body, hardReload } = mainState;
    const checkedItems = body.filter((e) => e.checked);
    const idArray = checkedItems.map((e) => e.ID);

    setOpenSelectInsertDialog(false);

    if (!idArray.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    _handleChange({ openBackdrop: true });

    const snackbarObj = {};
    const { success } = await resources.batchInsertCrawlerData({
      insertArray: idArray,
    });

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Success";
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Fail to insert data";
    }

    _handleChange({ openBackdrop: false, ...snackbarObj });
    setMainState({ hardReload: !hardReload });
  };

  useEffect(() => {
    async function getInitial() {
      await getVideoList();
      await getVideoResourceType();
    }
    setMainState({ getCount: mainState.getCount + 1 });
    getInitial();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.hardReload,
  ]);

  const header = useMemo(
    () => [
      {
        id: "check",
        label: (
          <Checkbox
            color="primary"
            checked={mainState.checkAll}
            onChange={handleCheckAll}
          />
        ),
        disabledSort: true,
      },
      { id: "name", label: "Video Name", disabledSort: true },
      { id: "type", label: "Target Website", disabledSort: true },
      { id: "embedLink", label: "Embed Link", disabledSort: true },
      { id: "thumbnail", label: "Thumbnail", disabledSort: true },
      { id: "models", label: "Models", disabledSort: true },
      { id: "tags", label: "Tags", disabledSort: true },
      // { id: 'downloadLink', label: 'Download Link', disabledSort: true  },
      { id: "trailerStatus", label: "Trailer", disabledSort: true },
      { id: "createdAt", label: "Created At" },
      { id: "action", label: "Action", disabledSort: true },
    ],
    [mainState.checkAll, mainState.body]
  );

  const body = generateBody(
    mainState.body,
    handleDynamicCheck,
    handleEditVideos,
    handleDelete,
    classes
  );

  return (
    <Container className={classes.tableWrapper}>
      <CustomDeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        name={mainState.deleteName}
        handleDelete={deleteResources}
      />

      <EditFormDialog
        open={openEditDialog}
        setOpen={setOpenEditDialog}
        setMainState={setMainState}
        handleUpdate={handleUpdateDetails}
        items={mainState.editItems}
        sourceTypeOption={mainState.videoResourceTypeOption}
      />

      <CustomInsertDialog
        open={openInsertDialog}
        setOpen={setOpenInsertDialog}
        handleInsert={bulkInsertData}
      />

      <CustomInsertSelectedDialog
        open={openSelectInsertDialog}
        setOpen={setOpenSelectInsertDialog}
        insertCount={mainState.insertCount}
        handleInsert={insertSelectedData}
      />

      <Paper classes={{ root: classes.filterWrapper }}>
        <div
          className={clsx(
            classes.filterTitleWrapper,
            classes.flexCenter,
            classes.flexJustifyCenter
          )}
        >
          <Typography className={classes.title}>Crawler Videos</Typography>
        </div>

        <div className={clsx(classes.inputWrapper)}>
          <div className={classes.insertButtonGroupWrapper}>
            <Button
              className={classes.insertButton}
              variant="contained"
              color="primary"
              onClick={handleInsertAlertBox}
            >
              Insert Data
            </Button>

            <Button
              className={classes.insertButton}
              variant="contained"
              color="primary"
              onClick={handleInsertSelectedAlertBox}
            >
              Insert Selected Data
            </Button>
          </div>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} md={6}>
              <CustomDatePicker
                label="Start Date"
                changeKey="startDate"
                date={mainState.startDate}
                handleDateChange={handleDateChange}
              />

              <CustomDatePicker
                label="End Date"
                changeKey="endDate"
                date={mainState.endDate}
                handleDateChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomSelect
                fullWidth
                label="Video Source Type"
                stateKey="type"
                value={mainState.type}
                items={mainState.typeArr}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      </Paper>

      <Container className={classes.tableInnerWrapper}>
        <CustomTable
          headCells={header}
          rows={body}
          page={mainState.page}
          pageItems={mainState.pageItems}
          rowsPerPage={mainState.rowsPerPage}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </Container>
    </Container>
  );
}

export default CrawlerResources;
