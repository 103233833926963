import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

// material UI
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';

// component
import {utilsContext} from "../../../contexts/utils";
import contents from "../../../api/contents";

const useStyles = makeStyles(theme => ({
  special: {
    color: 'red',
    fontSize: 30
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary
  },
  tableWrapper: {
    padding: 0
  },
  sections: {
    backgroundColor: 'yellow'
  },
  filterWrapper: {
    display: 'grid',
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: '-25px 15px 0',
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10
  },
  inputWidth: {
    width: '100%',
    marginBottom: 10,
  },
  actionWrapper: {
    textAlign: 'end',
  },
  dynamicArea: {
    padding: '40px 40px 0',
    position: 'relative'
  },
  dynamicSection: {
    backgroundColor: theme.palette.background.section,
    borderRadius: 10,
    padding: 10,
  },
  deleteSectionButton: {
    color: '#000000',
    position: 'absolute',
    right: 20,
    top: 20,
  },
  updateButton: {
    // width:
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main
  },
  // common
  columnHalfWidth: {
    width: '50%',
  },
  spacing: {
    marginTop: 10,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifySpaceEvenly: {
    justifyContent: 'space-evenly'
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between'
  }
}));

const initialDataFormat = {title: '', url: ''};

function Headers() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    dataArr: [{...initialDataFormat }]
  })

  // common functions
  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleDynamicChange = (key, index) => event => {
    const { dataArr } = mainState;
    const tmpArr = [...dataArr];
    tmpArr[index][key] = event.target.value;
    setMainState({ dataArr: tmpArr})
  }

  const handleAddSection = () => {
    const {dataArr} = mainState;
    const tmpArr = [...dataArr];
    tmpArr.push({ ...initialDataFormat });
    setMainState({ dataArr: tmpArr })
  }

  const handleDeleteSection = index => () => {
    const {dataArr} = mainState;
    const tmpArr = [...dataArr];
    tmpArr.splice(index, 1)
    setMainState({ dataArr: tmpArr })
  }

  // API
  const handleUpdate = async () => {
    const { dataArr } = mainState;
    let validation = false;

    dataArr.forEach(e => {
      if (!e.title || !e.url ) validation = true;
    })

    if (validation) {
      return _handleChange({ snackbar: true, snackbarType: 2, snackbarContent: 'Title or URL cannot be blank' })
    }

    const items = dataArr.map(e => ({title: e.title, link: e.url}))
    const { success, data } = await contents.updateHeader({ items });

    let snackbarType = 0;
    let snackbarContent = 'Successfully Update';

    if (!success) {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent })
  }

  useEffect(() => {
    async function getData() {
      _handleChange({ openBackdrop: true });
      const { success, data } = await contents.getHeader();
      _handleChange({ openBackdrop: false });

      if (!success) {
          return _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: data.message });
      }

      const dataArr = data.map(e => ({title: e.title, url: e.link}))
      setMainState({ dataArr })
    }

    getData();
  }, []);

  return (
    <Container className={classes.tableWrapper}>
      <Paper classes={{ root: classes.filterWrapper }}>
        <div className={clsx(classes.filterTitleWrapper, classes.flexCenter, classes.flexJustifyCenter)}>
          <Typography className={classes.title}>Headers</Typography>
        </div>

        {mainState.dataArr.map((item, index) => (
          <div className={classes.dynamicArea}>

            {index !== 0 &&
              <IconButton className={classes.deleteSectionButton} aria-label="delete" onClick={handleDeleteSection(index)}>
                <CancelIcon />
              </IconButton>
            }

            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.dynamicSection}
            >
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  className={clsx(classes.inputWidth, classes.spacing)}
                  value={item.title}
                  onChange={handleDynamicChange('title', index)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="URL"
                  className={clsx(classes.inputWidth, classes.spacing)}
                  value={item.url}
                  onChange={handleDynamicChange('url', index)}
                />
              </Grid>
            </Grid>
          </div>
        ))}

        <div className={classes.actionWrapper}>
          <IconButton color="primary" aria-label="delete" onClick={handleAddSection}>
            <AddBoxIcon />
          </IconButton>
        </div>

        <Button
          className={classes.updateButton}
          variant="contained"
          color="primary"
          onClick={handleUpdate}
        >
          Update
        </Button>

      </Paper>
    </Container>
  )


}

export default Headers;
