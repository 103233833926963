import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

// material UI
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";

// component
import CustomTable from "../../../components/CustomTable";
import CustomDeleteDialog from '../../../components/CustomDeleteDialog';
import {utilsContext} from "../../../contexts/utils";

import taxonomic from "../../../api/taxonomic";

const useStyles = makeStyles(theme => ({
  special: {
    color: 'red',
    fontSize: 30
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary
  },
  tableWrapper: {
    padding: 0
  },
  sections: {
    backgroundColor: 'yellow'
  },
  filterWrapper: {
    display: 'grid',
    marginBottom: 40
  },
  filterTitleWrapper: {
    margin: '-25px 15px 0',
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10
  },
  inputWrapper: {
    padding: theme.spacing(2),
  },
  inputWidth: {
    width: '100%',
    marginBottom: 10,
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main
  },
  // component
  deleteButton: {
    backgroundColor: '#f75145',
    color: theme.palette.primary.contrastText
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: '100%',
  },
  urlLink: {
    color: 'grey',
    textDecoration: 'underline',

    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  // common
  columnFullWidth: {
    width: '100%',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifySpaceEvenly: {
    justifyContent: 'space-evenly'
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between'
  }
}));

const header = [
  { id: 'id', label: 'ID', disabledSort: true },
  { id: 'name', label: 'Name' },
  { id: 'cnName', label: 'Chinese Name' },
  { id: 'jpName', label: 'Japanese Name' },
  { id: 'videos', label: 'Videos' },
  // { id: 'createdAt', label: 'Created At' },
  // { id: 'recommended', label: 'Recommended' },
  // { id: 'appendCategory', label: 'AppendCategory' },
  { id: 'order', label: 'Order' },
  { id: 'action', label: 'Action', disabledSort: true },
];

const generateBody = (items, mainState, handlePushUrl, handleUpdate, handleDelete, handleChangeCatList, classes, categories) => {
  return items.map((e, i) => ({
    id: ((mainState.page - 1) * mainState.rowsPerPage) + i + 1,
    name: <TextField
      value={e.name}
      onChange={handleChangeCatList('name', i)}
    />,
    cnName: <TextField
      value={e.cnName}
      onChange={handleChangeCatList('cnName', i)}
    />,
    jpName: <TextField
      value={e.jpName}
      onChange={handleChangeCatList('jpName', i)}
    />,
    videos: <span className={classes.urlLink}
      onClick={handlePushUrl(e.encodeID)}>{e.totalVideo}
    </span>,
    // createdAt: e.createdAt,
    // appendCategory: <FormControl className={clsx(classes.columnFullWidth)}>
    //   <InputLabel id="category-option-select">Category</InputLabel>
    //   <Select
    //     value={e.appendID}
    //     onChange={handleChangeCatList('appendID', i)}
    //   >
    //     <MenuItem key='' value=''>None</MenuItem>
    //     {categories.map(e => (
    //       <MenuItem key={e.label} value={e.value}>{e.label}</MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>,
    order: <TextField
      type="number"
      value={e.order}
      onChange={handleChangeCatList('order', i)}
    />,
    action: <div className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween)}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.updateButton}
        onClick={handleUpdate(e)}
      >
        Update
      </Button>
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  color="secondary"*/}
      {/*  className={classes.deleteButton}*/}
      {/*  onClick={handleDelete(e)}*/}
      {/*>*/}
      {/*  Delete*/}
      {/*</Button>*/}
    </div>
  }));
};

function Categories(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [bodyData, setBodyData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 15,
    rowsPerPage: 100,
    order: 'desc',
    orderBy: 'createdAT',
    startDate: null,
    endDate: null,
    body: [],
    categoryName: '',
    categoryCnName: '',
    categoryJpName: '',
    searchValue: '',
    deleteID: '',
    deleteName: '',
  });

  useEffect(() => {
    async function categoryList() {
      _handleChange({ openBackdrop: true });
      const { page, rowsPerPage, searchValue, order, orderBy } = mainState;
      const { success, data, descriptions } = await taxonomic.getCategoryList({
        page: page,
        limit: rowsPerPage,
        keyword: searchValue,
        sort: orderBy,
        sortBy: order
      });

      _handleChange({ openBackdrop: false });

      if (!success) {
        return _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: descriptions });
      }

      const newBodyData = data.list.map(e => ({
        ID: e.ID,
        encodeID: e.ID,
        name: e.name,
        cnName: e.cnName,
        jpName: e.jpName,
        totalVideo: e.totalVideo,
        appendID: '' ,
        appendCatName: '',
        appendCatOpen: false,
        recommended: '',
        order: e.order,
        createdAt: e.createdAt,
      }));

      // setCategories(data.categories);
      setBodyData(newBodyData);
      setMainState({ pageItems: data.total });
    }

    categoryList();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.searchValue, mainState.refresh]);


  // common functions
  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = key => event => {
    setMainState({ [key]: event.target.value })
  };

  const handleChangeCatList = (key, index) => event => {
    let newBodyData = [...bodyData];
    newBodyData[index][key] = event.target.value;
    setBodyData(newBodyData);
  };

  const keyPress = (e) => {
    if (mainState.categoryName && mainState.categoryCnName)  {
      if (e.keyCode === 13) createCategory();
    }
  };

  const handlePushUrl = id => () => {
    props.history.push(`/videos?category=${id}`)
  };

  // API functions
  const handleUpdate = details => async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await taxonomic.updateCategory(details.ID, details);

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = 'Successfully Updated!'
    } else {
      snackbarType = 1;
      snackbarContent = data.message
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
  };

  const handleDelete = details => async () => {
    setMainState({ deleteID: details.ID, deleteName: details.name });
    setOpenDialog(true);
  };

  const createCategory = async () => {
    const { categoryName, categoryCnName, categoryJpName } = mainState;

    try {
      if (!categoryName) throw 'Name cannot be blank';
      if (!categoryCnName) throw 'Chinese Name cannot be blank';
      if (!categoryJpName) throw 'Japanese Name cannot be blank';
    } catch (err) {
      return _handleChange({ snackbar: true, snackbarType: 2, snackbarContent: err });
    }

    _handleChange({ openBackdrop: true });
    const { success, data } = await taxonomic.createCategory({ categoryName, categoryCnName, categoryJpName });
    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = 'Successfully created!'
    } else {
      snackbarType = 1;
      snackbarContent = data.message
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setMainState({ categoryName: '', categoryCnName: '', categoryJpName: '', page: 1, refresh: !mainState.refresh })
  };

  const deleteTags = async () => {
    const { deleteID } = mainState;
    _handleChange({ openBackdrop: true });

    const { success, data } = await taxonomic.deleteCategory(deleteID);

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = 'Successfully deleted!'
    } else {
      snackbarType = 1;
      snackbarContent = data.message
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setOpenDialog(false);
    setMainState({ page: 1, refresh: !mainState.refresh })
  };

  const body = generateBody(bodyData, mainState, handlePushUrl, handleUpdate, handleDelete, handleChangeCatList, classes, categories);

  return (
    <div>
      <Container className={classes.tableWrapper}>
        <CustomDeleteDialog
          open={openDialog}
          setOpen={setOpenDialog}
          name={mainState.deleteName}
          handleDelete={deleteTags}
        />
        <Paper classes={{ root: classes.filterWrapper }}>
          <div className={clsx(classes.filterTitleWrapper, classes.flexCenter, classes.flexJustifyCenter)}>
            <Typography className={classes.title}>Categories</Typography>
          </div>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            className={classes.inputWrapper}
          >
            <Grid item xs={12} sm={8}>
              <TextField
                id="tag-text"
                label="Category Name"
                className={classes.inputWidth}
                value={mainState.categoryName}
                onChange={handleChange('categoryName')}
                onKeyDown={keyPress}
              />
              <TextField
                id="tag-cn-name"
                label="Category Chinese Name"
                className={classes.inputWidth}
                value={mainState.categoryCnName}
                onChange={handleChange('categoryCnName')}
                onKeyDown={keyPress}
              />
              <TextField
                id="tag-jp-name"
                label="Category Japanese Name"
                className={classes.inputWidth}
                value={mainState.categoryJpName}
                onChange={handleChange('categoryJpName')}
                onKeyDown={keyPress}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                className={classes.addButton}
                variant="contained"
                color="primary"
                onClick={createCategory}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <CustomTable
          search={search}
          setSearch={setSearch}
          headCells={header}
          rows={body}
          page={mainState.page}
          pageItems={mainState.pageItems}
          rowsPerPage={mainState.rowsPerPage}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </Container>
    </div>
  )
}

export default Categories;
