import React, { useLayoutEffect, useState } from "react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faBookOpen,
  faUserTie,
  faAudioDescription,
  faTag,
  faTags,
  faListUl,
  faEdit,
  faArchive,
  faFileVideo,
} from "@fortawesome/free-solid-svg-icons";

import ToolbarDropMenu from "../components/ToolbarDropMenu";

import sidebarBackground from "../assets/sidebar.jpg";
import videoIcon from "../assets/video-icon.png";

const drawerWidth = 245;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "transparent",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mobileAppBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileAppBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  siteIcon: {
    width: 45,
  },
  siteTittle: {
    fontSize: 18,
    color: theme.palette.secondary.main,
  },
  menuButton: {
    marginRight: 36,
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.secondary.main,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerBox: {
    overflowX: "hidden",
    backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.7), 
          rgba(0, 0, 0, 0.7)),
          url(${sidebarBackground})`,
    backgroundSize: "cover",
    boxShadow:
      "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  baseToolbar: {
    backgroundColor: "transparent",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    marginBottom: 0,
    ...theme.mixins.toolbar,
  },
  toolBarWrapper: {
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  mobileContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  ListTitleWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  panelText: {
    color: theme.palette.secondary.main,
    padding: theme.spacing(0, 2),
  },
  panelIcon: {
    color: theme.palette.secondary.main,
  },
}));

const ExpansionPanel = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": { borderBottom: 0 },
    "&:before": { display: "none" },
    "&$expanded": { margin: 0 },
    backgroundColor: "transparent",
  },
  expanded: {},
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto",
    },
  },
  content: {
    color: theme.palette.secondary.main,
    "&$expanded": { margin: 0 },
  },
  expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: { padding: 0 },
}))(MuiExpansionPanelDetails);

const LinkItem = withStyles((theme) => ({
  noWrap: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  active: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.secondary.dark,
    "& div": {
      color: theme.palette.primary.main,
    },
  },
  linkText: {
    justifyContent: "center",
    transition: "all 0.3s",
    color: theme.palette.secondary.main,
    "&:hover div": {
      color: theme.palette.primary.main,
    },
  },
  typography: {
    fontSize: "1em !important",
  },
}))((props) => {
  const { classes, text, icon, to, exact, handleClose } = props;
  const renderLink = React.forwardRef((itemProps, ref) => (
    <NavLink to={to} {...itemProps} />
  ));

  return (
    <ListItem
      className={classes.linkText}
      component={renderLink}
      to={to}
      onClick={handleClose}
      activeClassName={classes.active}
      exact={exact}
    >
      <ListItemIcon className={classes.linkText}>{icon}</ListItemIcon>
      <ListItemText
        classes={{ primary: classes.typography }}
        className={classes.noWrap}
        primary={text}
      />
    </ListItem>
  );
});

const MListTitle = (props) => {
  const classes = useStyles();
  const { open, icon, title } = props;

  return (
    <React.Fragment>
      {open ? (
        <div className={classes.ListTitleWrapper}>
          <FontAwesomeIcon icon={icon} />
          <Typography className={classes.panelText}>{title}</Typography>
        </div>
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </React.Fragment>
  );
};

const MDivider = withStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
  },
}))(Divider);

function DrawerList(props) {
  const classes = useStyles();
  const { handleDrawerClose, theme, device, open } = props;

  return (
    <React.Fragment>
      <div className={classes.toolbar}>
        <img src={videoIcon} alt="site-icon" className={classes.siteIcon} />
        <Typography className={classes.siteTittle}>JavPT</Typography>
        <IconButton
          onClick={device ? handleDrawerClose : handleDrawerClose(false)}
          classes={{
            root: classes.iconButton,
          }}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <MDivider />

      <ExpansionPanel defaultExpanded classes={{ root: classes.drawerPaper }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header-admin"
        >
          <MListTitle title="Admin Panel" icon={faUserTie} open={open} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <LinkItem
              text="Dashboard"
              exact
              to="/admin/dashboard"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faChartLine} />}
            />

            {/*<LinkItem text="Admins" exact to="/admin" handleClose={handleDrawerClose}*/}
            {/*          icon={<FontAwesomeIcon icon={faListUl}/>}/>*/}

            {/*<LinkItem text="Create Admin" exact to="/admin/edit" handleClose={handleDrawerClose}*/}
            {/*          icon={<FontAwesomeIcon icon={faEdit}/>}/>*/}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded classes={{ root: classes.drawerPaper }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header-content"
        >
          <MListTitle title="Generals" icon={faBookOpen} open={open} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            {/*<LinkItem text="Header Nav Bar" exact to="/content/headers" handleClose={handleDrawerClose}*/}
            {/*          icon={<FontAwesomeIcon icon={faListUl}/>}/>*/}

            {/*<LinkItem text="Footer Content" exact to="/content/footers" handleClose={handleDrawerClose}*/}
            {/*          icon={<FontAwesomeIcon icon={faListUl}/>}/>*/}

            <LinkItem
              text="Video Sources"
              exact
              to="/content/video-resource"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faFileVideo} />}
            />
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded classes={{ root: classes.drawerPaper }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header-content"
        >
          <MListTitle title="Taxonomic" icon={faTags} open={open} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <LinkItem
              text="Categories"
              exact
              to="/taxonomic/categories"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faArchive} />}
            />

            <LinkItem
              text="Tags"
              exact
              to="/taxonomic/tags"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faTag} />}
            />

            <LinkItem
              text="Models"
              exact
              to="/taxonomic/models"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faTag} />}
            />

            <LinkItem
              text="Studio"
              exact
              to="/taxonomic/studio"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faArchive} />}
            />
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded classes={{ root: classes.drawerPaper }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header-content"
        >
          <MListTitle title="Resources" icon={faAudioDescription} open={open} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <LinkItem
              text="Videos"
              exact
              to="/videos"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faListUl} />}
            />

            <LinkItem
              text="Create Video"
              exact
              to="/videos/create"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faEdit} />}
            />
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded classes={{ root: classes.drawerPaper }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.panelIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header-content"
        >
          <MListTitle
            title="Crawler Resources"
            icon={faAudioDescription}
            open={open}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List>
            <LinkItem
              text="Videos"
              exact
              to="/crawler"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faListUl} />}
            />

            <LinkItem
              text="Pre Fill"
              exact
              to="/crawler/prefill"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faListUl} />}
            />

            <LinkItem
              text="Streaming Resources"
              exact
              to="/crawler/prefill-url"
              handleClose={handleDrawerClose}
              icon={<FontAwesomeIcon icon={faListUl} />}
            />

            {/*<LinkItem text="Create Video" exact to="/crawler/:resourceID/edit" handleClose={handleDrawerClose}*/}
            {/*          icon={<FontAwesomeIcon icon={faEdit}/>}/>*/}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
}

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default function NavBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [width] = useWindowSize();
  const isMobile = width < 475 || false;

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = (open) => (event) => setOpen(open);
  const handleMobileDrawerOpen = () => setOpen(!open);

  // Mobile Navbar css layout
  const appBarClass = isMobile
    ? clsx(classes.mobileAppBar, { [classes.mobileAppBarShift]: open })
    : clsx(classes.appBar, { [classes.appBarShift]: open });
  const iconButtonClass = isMobile
    ? clsx(classes.menuButton, open && classes.hide)
    : clsx(classes.menuButton, { [classes.hide]: open });
  const drawerClass = isMobile
    ? classes.drawer
    : clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      });
  const drawerPaperClass = isMobile
    ? clsx(classes.drawerPaper, classes.drawerBox)
    : clsx(classes.drawerBox, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      });
  const drawerStyle = isMobile ? "persistent" : "permanent";
  const contentClass = isMobile ? classes.mobileContent : classes.content;

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* Desktop layout */}
      <AppBar position="fixed" className={appBarClass}>
        <Toolbar className={classes.toolBarWrapper}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={isMobile ? handleDrawerOpen(true) : handleMobileDrawerOpen}
            edge="start"
            className={iconButtonClass}
          >
            <MenuIcon />
          </IconButton>
          <div />
          <ToolbarDropMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant={drawerStyle}
        open={open}
        anchor="left"
        className={drawerClass}
        classes={{
          paper: drawerPaperClass,
        }}
      >
        <DrawerList
          device={isMobile}
          theme={theme}
          open={open}
          handleDrawerClose={
            isMobile ? handleMobileDrawerOpen : handleDrawerOpen
          }
        />
      </Drawer>

      <main className={contentClass}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
