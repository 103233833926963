import React from 'react';

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid, IconButton, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomSelect from "../../components/CustomSelect";

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    minWidth: 700,
    paddingBottom: 80,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    }
  },
  dynamicArea: {
    position: 'relative',
  },
  inputWrapper: {
    backgroundColor: theme.palette.background.section,
    marginTop: 20,
    borderRadius: 10,
  },
  buttonWrapper: {
    textAlign: 'end',
    width: '100%',
    margin: '10px 0 30px',
  },
  actionWrapper: {
    textAlign: 'end',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    backgroundColor: '#ffffff',
    paddingRight: 20,
  },
  deleteSectionButton: {
    color: '#000000',
    position: 'absolute',
    right: -28,
    top: -23,
  },
}))

function CreateFormDialog(props) {
  const {open, setOpen, items, defaultItem, setMainState, category, categories, handleSubmit} = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = key => event => {
    setMainState({ [key]: event.target.value });
  }

  const handleDynamicChange = (key, index) => event => {
    const newItem = [...items];
    newItem[index][key] = event.target.value;
    setMainState({ createDialogItem: newItem })
  }

  const handleAddSection = () => {
    const newItem = [...items];
    newItem.push({...defaultItem})
    setMainState({ createDialogItem: newItem })
  }

  const handleDeleteSection = index => () => {
    const newItem = [...items];
    newItem.splice(index, 1)
    setMainState({ createDialogItem: newItem })
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Create
      </DialogTitle>
      <DialogContent className={classes.dialogWrapper}>
        <CustomSelect
          fullWidth
          label="Category"
          stateKey="createDialogCategory"
          value={category}
          items={categories}
          handleChange={handleChange}
        />

        {items.map((item, index) => (
          <div className={classes.dynamicArea}>

            {index !== 0 &&
              <IconButton className={classes.deleteSectionButton} aria-label="delete"
                          onClick={handleDeleteSection(index)}>
                <CancelIcon/>
              </IconButton>
            }

            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Chinese Name"
                  fullWidth
                  value={item.name}
                  onChange={handleDynamicChange('name', index)}
                />
              </Grid>
              {/*<Grid item xs={12} md={6}>*/}
              {/*  <TextField*/}
              {/*    id="videoSource"*/}
              {/*    label="Video Source"*/}
              {/*    fullWidth*/}
              {/*    value={item.videoSource}*/}
              {/*    onChange={handleDynamicChange('videoSource', index)}*/}
              {/*  />*/}
              {/*</Grid>*/}
            </Grid>
          </div>
        ))}

        <div className={classes.actionWrapper}>
          <IconButton color="primary" aria-label="delete" onClick={handleAddSection}>
            <AddBoxIcon />
          </IconButton>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>

      </DialogContent>
    </Dialog>
  )
}

export default CreateFormDialog;
