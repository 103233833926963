import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// material UI
import {
  Container,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

// component
import CustomTable from "../../../components/CustomTable";
import CustomDeleteDialog from "../../../components/CustomDeleteDialog";
import { utilsContext } from "../../../contexts/utils";

import taxonomic from "../../../api/taxonomic";

const useStyles = makeStyles((theme) => ({
  special: {
    color: "red",
    fontSize: 30,
  },
  // wrapper
  wrapper: {
    backgroundColor: theme.palette.secondary,
  },
  tableWrapper: {
    padding: 0,
  },
  sections: {
    backgroundColor: "yellow",
  },
  filterWrapper: {
    display: "grid",
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10,
  },
  inputWrapper: {
    padding: theme.spacing(2),
  },
  inputWidth: {
    width: "100%",
    marginBottom: 10,
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
  },
  // component
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  addButton: {
    width: "100%",
  },
  urlLink: {
    color: "grey",
    textDecoration: "underline",

    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  // common
  columnFullWidth: {
    width: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

const header = [
  { id: "id", label: "ID", disabledSort: true },
  { id: "name", label: "Name" },
  { id: "cnName", label: "Chinese Name" },
  { id: "jpName", label: "Japanese Name" },
  { id: "videos", label: "Videos" },
  // { id: 'createdAt', label: 'Created At' },
  // { id: 'category', label: 'Category', disabledSort: true },
  { id: "action", label: "Action", disabledSort: true },
];

const generateBody = (
  items,
  page,
  rowsPerPage,
  categories,
  handlePushUrl,
  handleChangeTagList,
  handleUpdate,
  handleDelete,
  classes
) => {
  return items.map((e, i) => ({
    id: (page - 1) * rowsPerPage + i + 1,
    name: (
      <TextField value={e.name} onChange={handleChangeTagList("name", i)} />
    ),
    cnName: (
      <TextField value={e.cnName} onChange={handleChangeTagList("cnName", i)} />
    ),
    jpName: (
      <TextField value={e.jpName} onChange={handleChangeTagList("jpName", i)} />
    ),
    videos: (
      <span className={classes.urlLink} onClick={handlePushUrl(e.name)}>
        {e.totalVideo}
      </span>
    ),
    // category: <FormControl className={clsx(classes.columnFullWidth)}>
    //   <InputLabel id="category-option-select">Category</InputLabel>
    //   <Select
    //     value={e.category}
    //     onChange={handleChangeTagList('category', i)}
    //   >
    //     <MenuItem key='' value=''>None</MenuItem>
    //     {categories.map(e => (
    //       <MenuItem key={e.label} value={e.value}>{e.label}</MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>,
    action: (
      <div
        className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween)}
      >
        <Button
          variant="contained"
          color="secondary"
          className={classes.updateButton}
          onClick={handleUpdate(e)}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.deleteButton}
          onClick={handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    ),
  }));
};

function Models(props) {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [mainState, _setMainState] = useState({
    page: 1,
    pageItems: 15,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    startDate: null,
    endDate: null,
    body: [],
    modelName: "",
    modelCnName: "",
    modelJpName: "",
    searchValue: "",
    deleteID: "",
    deleteName: "",
  });

  // useEffect(() => {
  //   async function categoryList() {
  //     _handleChange({ openBackdrop: true });
  //     const { success, data, descriptions } = await taxonomic.getMainCategory();
  //
  //     _handleChange({ openBackdrop: false });
  //
  //     if (!success) {
  //       return _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: descriptions });
  //     }
  //
  //     setCategories(data.categories);
  //   }
  //
  //   categoryList();
  // }, []);

  useEffect(() => {
    async function modelList() {
      _handleChange({ openBackdrop: true });
      const { page, rowsPerPage, searchValue, order, orderBy } = mainState;
      const { success, data, descriptions } = await taxonomic.getModelList({
        page: page,
        limit: rowsPerPage,
        keyword: searchValue,
        sort: orderBy,
        sortBy: order,
      });

      _handleChange({ openBackdrop: false });

      if (!success) {
        return _handleChange({
          snackbar: true,
          snackbarType: 1,
          snackbarContent: descriptions,
        });
      }

      setBodyData(data.list);
      setMainState({ pageItems: data.total });
    }

    modelList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.searchValue,
    mainState.refresh,
  ]);

  // common functions
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleChangeTagList = (key, index) => (event) => {
    let newBodyData = [...bodyData];
    newBodyData[index][key] = event.target.value;
    setBodyData(newBodyData);
  };

  const keyPress = (e) => {
    const { modelName, modelCnName, modelJpName } = mainState;

    if (modelName && modelCnName && modelJpName) {
      if (e.keyCode === 13) createModels();
    }
  };

  const handlePushUrl = (name) => () => {
    props.history.push(`/videos?searchModel=${name}`);
  };

  // API functions
  const handleUpdate = (details) => async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await taxonomic.updateModel(details.ID, details);

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully Updated!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
  };

  const handleDelete = (details) => async () => {
    setMainState({ deleteID: details.ID, deleteName: details.name });
    setOpenDialog(true);
  };

  const createModels = async () => {
    const { modelName, modelCnName, modelJpName } = mainState;

    try {
      if (!modelName) throw "Name cannot be blank";
      if (!modelCnName) throw "Chinese name cannot be blank";
      if (!modelJpName) throw "Japanese name cannot be blank";
    } catch (err) {
      return _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: err,
      });
    }

    _handleChange({ openBackdrop: true });
    const { success, data } = await taxonomic.createModel({
      name: modelName,
      cnName: modelCnName,
      jpName: modelJpName,
    });
    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully created!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setMainState({
      modelName: "",
      modelCnName: "",
      modelJpName: "",
      page: 1,
      refresh: !mainState.refresh,
    });
  };

  const deleteModels = async () => {
    const { deleteID } = mainState;
    _handleChange({ openBackdrop: true });

    const { success, data } = await taxonomic.deleteModel(deleteID);

    _handleChange({ openBackdrop: false });

    let snackbarType, snackbarContent;
    if (success) {
      snackbarType = 0;
      snackbarContent = "Successfully deleted!";
    } else {
      snackbarType = 1;
      snackbarContent = data.message;
    }

    _handleChange({ snackbar: true, snackbarType, snackbarContent });
    setOpenDialog(false);
    setMainState({ page: 1, refresh: !mainState.refresh });
  };

  const body = generateBody(
    bodyData,
    mainState.page,
    mainState.rowsPerPage,
    categories,
    handlePushUrl,
    handleChangeTagList,
    handleUpdate,
    handleDelete,
    classes
  );

  return (
    <div>
      <Container className={classes.tableWrapper}>
        <CustomDeleteDialog
          open={openDialog}
          setOpen={setOpenDialog}
          name={mainState.deleteName}
          handleDelete={deleteModels}
        />
        <Paper classes={{ root: classes.filterWrapper }}>
          <div
            className={clsx(
              classes.filterTitleWrapper,
              classes.flexCenter,
              classes.flexJustifyCenter
            )}
          >
            <Typography className={classes.title}>Models</Typography>
          </div>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            className={classes.inputWrapper}
          >
            <Grid item xs={12} sm={8}>
              <TextField
                id="tag-text"
                label="Name"
                className={classes.inputWidth}
                value={mainState.modelName}
                onChange={handleChange("modelName")}
                onKeyDown={keyPress}
              />
              <TextField
                id="tag-text-cn"
                label="Chinese Name"
                className={classes.inputWidth}
                value={mainState.modelCnName}
                onChange={handleChange("modelCnName")}
                onKeyDown={keyPress}
              />
              <TextField
                id="tag-text-jp"
                label="Japanese Name"
                className={classes.inputWidth}
                value={mainState.modelJpName}
                onChange={handleChange("modelJpName")}
                onKeyDown={keyPress}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                className={classes.addButton}
                variant="contained"
                color="primary"
                onClick={createModels}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <CustomTable
          search={search}
          setSearch={setSearch}
          headCells={header}
          rows={body}
          page={mainState.page}
          pageItems={mainState.pageItems}
          rowsPerPage={mainState.rowsPerPage}
          order={mainState.order}
          orderBy={mainState.orderBy}
          setState={setMainState}
        />
      </Container>
    </div>
  );
}

export default Models;
