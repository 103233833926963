import fetchData from "../utils/fetchData";

function getStudioList(params) {
  return fetchData({
    url: "/taxonomy/panel/studio",
    method: "GET",
    params: params,
  });
}

function createStudio(data) {
  return fetchData({
    url: "/taxonomy/studio",
    method: "POST",
    data: data,
  });
}

function updateStudio(studioID, data) {
  return fetchData({
    url: "/taxonomy/studio/" + studioID,
    method: "PUT",
    data: data,
  });
}

function deleteStudio(studioID) {
  return fetchData({
    url: "/taxonomy/studio/" + studioID,
    method: "DELETE",
  });
}

function getMainCategory() {
  return fetchData({
    url: "/general/main-categories",
    method: "GET",
  });
}

function createCategory(data) {
  return fetchData({
    url: "/taxonomy/category",
    method: "POST",
    data: data,
  });
}

function getCategoryList(params) {
  return fetchData({
    url: "/taxonomy/category",
    method: "GET",
    params: params,
  });
}

function updateCategory(catID, data) {
  return fetchData({
    url: "/taxonomy/category/" + catID,
    method: "PUT",
    data: data,
  });
}

function deleteCategory(catID) {
  return fetchData({
    url: "/taxonomic/categories/" + catID,
    method: "DELETE",
  });
}

function createTag(data) {
  return fetchData({
    url: "/taxonomy/tags",
    method: "POST",
    data: data,
  });
}

function getTagList(params) {
  return fetchData({
    url: "/taxonomy/panel/tags",
    method: "GET",
    params: params,
  });
}

function updateTag(tagID, data) {
  return fetchData({
    url: "/taxonomy/tags/" + tagID,
    method: "PUT",
    data: data,
  });
}

function deleteTag(tagID) {
  return fetchData({
    url: "/taxonomy/tags/" + tagID,
    method: "DELETE",
  });
}

function createModel(data) {
  return fetchData({
    url: "/taxonomy/models",
    method: "POST",
    data: data,
  });
}

function getModelList(params) {
  return fetchData({
    url: "/taxonomy/panel/models",
    method: "GET",
    params: params,
  });
}

function updateModel(modelID, data) {
  return fetchData({
    url: "/taxonomy/models/" + modelID,
    method: "PUT",
    data: data,
  });
}

function deleteModel(modelID) {
  return fetchData({
    url: "/taxonomy/models/" + modelID,
    method: "DELETE",
  });
}

export default {
  getStudioList,
  createStudio,
  updateStudio,
  deleteStudio,
  getMainCategory,
  createCategory,
  getCategoryList,
  updateCategory,
  deleteCategory,
  createTag,
  getTagList,
  updateTag,
  deleteTag,
  createModel,
  getModelList,
  updateModel,
  deleteModel,
};
