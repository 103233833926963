import fetchData from "../utils/fetchData";

function getHeader() {
  return fetchData({
    url: "/contents/header",
    method: "GET",
  });
}

function updateHeader(data) {
  return fetchData({
    url: "/contents/header",
    method: "PUT",
    data: data,
  });
}

function getFooter(params) {
  return fetchData({
    url: "/contents/footer",
    method: "GET",
    params: params,
  });
}

function updateFooter(data) {
  return fetchData({
    url: "/contents/footer",
    method: "PUT",
    data: data,
  });
}

function getVideoResources(params) {
  return fetchData({
    url: "/video/source-type",
    method: "GET",
    params: params,
  });
}

function createVideoResources(data) {
  return fetchData({
    url: "/general/video-resource-type",
    method: "POST",
    data: data,
  });
}

function updateVideoResources(typeID, data) {
  return fetchData({
    url: "/video/source-type/" + typeID,
    method: "PUT",
    data: data,
  });
}

function bulkUpdateVideoResources(data) {
  return fetchData({
    url: "/video/source-type/bulk-update",
    method: "PUT",
    data: data,
  });
}

function deleteVideoResources(typeID) {
  return fetchData({
    url: "/general/video-resource-type/" + typeID,
    method: "DELETE",
  });
}

export default {
  getHeader,
  updateHeader,
  getFooter,
  updateFooter,
  getVideoResources,
  createVideoResources,
  updateVideoResources,
  bulkUpdateVideoResources,
  deleteVideoResources,
};
