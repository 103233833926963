import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { utilsContext } from "../../contexts/utils";
// Material UI
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import useQuery from "../../hooks/useQuery";
// Components
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/CustomTable";
import StatusIcon from "./StatusIcon";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import AlertDialog from "../../components/AlertDialog";
import EditDialog from "./FormDialog";
import resources from "../../api/resources";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    padding: 0,
  },
  filterWrapper: {
    display: "grid",
    padding: 0,
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 300,
    borderRadius: 10,
  },
  formContainer: {
    padding: "15px 30px",
    marginTop: 20,
  },
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
  },
  insertButtonGroupWrapper: {
    textAlign: "end",
    margin: 0,
    marginTop: 20,
    left: "25%",
  },
  insertButton: {
    marginRight: 15,
  },
  searchButton: {
    borderRadius: "0 0 5px 5px",
    width: "100%",
    marginTop: 30,
  },
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  // common
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

const generateBody = (
  items,
  page,
  rowsPerPage,
  categoryOption,
  handleCheck,
  handleEdit,
  classes
) => {
  return items.map((e, i) => ({
    check: (
      <Checkbox color="primary" onChange={handleCheck(i)} checked={e.checked} />
    ),
    videoName: e.name,
    streamtape: <StatusIcon status={e.streamtape} />,
    turbo: <StatusIcon status={e.turbo} />,
    doodstream: <StatusIcon status={e.doodstream} />,
    streamwish: <StatusIcon status={e.streamwish} />,
    status: e.status ? <DoneIcon /> : <CloseIcon />,
    createdAt: e.createdAt,
    action: (
      <Button variant="contained" color="primary" onClick={handleEdit(e.ID)}>
        View
      </Button>
    ),
  }));
};

function StreamingResource() {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useContext(utilsContext);
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [cloneDialog, setCloneDialog] = useState(false);
  const [syncDialog, setSyncDialog] = useState(false);
  const [syncCrawlerDialog, setSyncCrawlerDialog] = useState(false);
  const [updateToVideoDialog, setUpdateToVideoDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [forceLoad, setForceLoad] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    pageItems: 15,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "createdAt",
    checkAll: false,
    searchValue: "",
    type: 0,
    typeArr: [
      { label: "All", value: 0 },
      { label: "Not Sync", value: 1 },
      { label: "Synced", value: 2 },
      { label: "Ready To Sync", value: 3 },
    ],
    body: [
      // {checked: false, ID: 1, name: 'SomeVideo-01', streamtape: 1, ninjastream: 1, streamsb: 1, fembed: 1, status: 1, createdAt: '2020/10/10'},
      // {checked: false, ID: 2, name: 'SomeVideo-02', streamtape: 1, ninjastream: 1, streamsb: 0, fembed: 1, status: 0, createdAt: '2020/10/10'},
      // {checked: false, ID: 3, name: 'SomeVideo-02', streamtape: 1, ninjastream: 2, streamsb: 0, fembed: 1, status: 0, createdAt: '2020/10/10'},
      // {checked: false, ID: 4, name: 'SomeVideo-03', streamtape: 1, ninjastream: 2, streamsb: 0, fembed: 1, status: 0, createdAt: '2020/10/10'},
      // {checked: false, ID: 5, name: 'SomeVideo-04', streamtape: 3, ninjastream: 3, streamsb: 3, fembed: 3, status: 0, createdAt: '2020/10/10'},
      // {checked: false, ID: 6, name: 'SomeVideo-05', streamtape: 3, ninjastream: 3, streamsb: 3, fembed: 3, status: 0, createdAt: '2020/10/10'},
    ],
    bulkSelectCount: 0,
    dialogItems: {
      name: "",
      status: 0,
      originalURL: "",
      streamtape: "",
      streamtapeStatus: 0,
      ninjastream: "",
      ninjastreamStatus: 0,
      streamsb: "",
      streamsbStatus: 0,
      fembed: "",
      fembedStatus: 0,
    },
    getCount: 0,
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleCheckAll = (event) => {
    const tmpBody = [...mainState.body];
    tmpBody.forEach((e) => {
      e.checked = event.target.checked;
    });
    setMainState({ body: tmpBody, checkAll: event.target.checked });
  };

  const handleDynamicCheck = (index) => (event) => {
    const tmpBody = [...mainState.body];
    tmpBody[index].checked = event.target.checked;
    setMainState({ body: tmpBody, checkAll: false });
  };

  const handleCloneURLDialog = () => {
    setCloneDialog(true);
  };

  const handleSyncDialog = () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);
    setMainState({ bulkSelectCount: selectedList.length });
    setSyncDialog(true);
  };

  const handleSyncCrawlerDialog = () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);
    setMainState({ bulkSelectCount: selectedList.length });
    setSyncCrawlerDialog(true);
  };

  const handleUpdateToVideoDialog = () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);
    setMainState({ bulkSelectCount: selectedList.length });
    setUpdateToVideoDialog(true);
  };

  const handleBulkDeleteDialog = () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);
    setMainState({ bulkSelectCount: selectedList.length });
    setBulkDeleteDialog(true);
  };

  const handleSearch = async () => {
    const { page } = mainState;
    // await getVideoList(1, searchValue);
    if (page === 1) {
      setForceLoad(!forceLoad);
    } else {
      setMainState({ page: 1 });
    }
  };

  // API
  const handleBulkDelete = async () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);

    if (!selectedList.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    _handleChange({ openBackdrop: true });
    const bodyData = selectedList.map((e) => e.ID);
    const { success } = await resources.bulkDelete({ IDArray: bodyData });
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Deleted";
      setMainState({ checkAll: false });
      setForceLoad(!forceLoad);
      setBulkDeleteDialog(false);
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Fail to delete video";
    }
    _handleChange({ openBackdrop: false, ...snackbarObj });
  };

  const hanldeEditDialog = (ID) => async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await resources.getTheCrawlerManagerVideo(ID);
    const failureObj = {};

    if (success) {
      console.log(data);
      const dataFormat = {
        name: data.name,
        status: data.status,
        originalURL: data.originalURL,
        streamtape: data.streamtape.url,
        streamtapeStatus: data.streamtape.status,
        ninjastream: data.ninjastream.url,
        ninjastreamStatus: data.ninjastream.status,
        streamsb: data.streamsb.url,
        streamsbStatus: data.streamsb.status,
        fembed: data.fembed.url,
        fembedStatus: data.fembed.status,
        turbo: data.turbo.url,
        turboStatus: data.turbo.status,
        doodstream: data.doodstream.url,
        doodstreamStatus: data.doodstream.status,
        streamwish: data.streamwish.url,
        streamwishStatus: data.streamwish.status,
      };

      setMainState({ dialogItems: dataFormat });
      setEditDialog(true);
    } else {
      failureObj.snackbar = true;
      failureObj.snackbarType = 1;
      failureObj.snackbarContent = "Fail to get list";
    }

    _handleChange({ openBackdrop: false, ...failureObj });
  };

  const getVideoList = async () => {
    _handleChange({ openBackdrop: true });
    const { page, rowsPerPage, order, orderBy, type, getCount, searchValue } =
      mainState;
    const params = {
      page: page,
      limit: rowsPerPage,
      sort: orderBy,
      sortBy: order,
      status: type === 0 ? null : type - 1,
      keyword: searchValue === "" ? null : searchValue,
    };

    const { success, data } = await resources.getCrawlerManagerVideos(params);
    const failureObj = {};

    if (success) {
      const tmpArr = data.data.map((e) => ({
        checked: false,
        ID: e.ID,
        name: e.name,
        streamtape: e.streamtape,
        ninjastream: e.ninjastream,
        streamsb: e.streamsb,
        fembed: e.fembed,
        turbo: e.turbo,
        streamwish: e.streamwish,
        doodstream: e.doodstream,
        status: e.status,
        createdAt: e.createdAt,
      }));
      setMainState({ body: tmpArr, pageItems: data.total });
    } else {
      failureObj.snackbar = true;
      failureObj.snackbarType = 1;
      failureObj.snackbarContent = "Fail to get list";
    }

    if (getCount !== 0) setQuery(params);
    _handleChange({ openBackdrop: false, ...failureObj });
  };

  const cloneVideoURL = async () => {
    _handleChange({ openBackdrop: true });

    const { success, data } = await resources.cloneVideoURL();
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Cloned";
      setForceLoad(!forceLoad);
      setCloneDialog(false);
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = data.message;
    }
    _handleChange({ openBackdrop: false, ...snackbarObj });
  };

  const syncPlatform = async () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);

    if (!selectedList.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    _handleChange({ openBackdrop: true });
    const bodyData = selectedList.map((e) => e.ID);
    const { success, data } = await resources.syncPlatform({ list: bodyData });
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Synced";
      setMainState({ checkAll: false });
      setForceLoad(!forceLoad);
      setSyncDialog(false);
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = data.message;
    }
    _handleChange({ openBackdrop: false, ...snackbarObj });
  };

  const updateToVideo = async () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);

    if (!selectedList.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    _handleChange({ openBackdrop: true });
    const bodyData = selectedList.map((e) => e.ID);
    const { success } = await resources.updateToVideo({
      updateArray: bodyData,
    });
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Synced";
      setMainState({ checkAll: false });
      setForceLoad(!forceLoad);
      setUpdateToVideoDialog(false);
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Fail to sync video";
    }
    _handleChange({ openBackdrop: false, ...snackbarObj });
  };

  const syncLocal = async () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);

    if (!selectedList.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    _handleChange({ openBackdrop: true });
    const bodyData = selectedList.map((e) => e.ID);
    const { success } = await resources.syncLocal({ list: bodyData });
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Synced";
      setMainState({ checkAll: false });
      setForceLoad(!forceLoad);
      setSyncCrawlerDialog(false);
    } else {
      snackbarObj.snackbar = true;
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Fail to sync video";
    }
    _handleChange({ openBackdrop: false, ...snackbarObj });
  };

  useEffect(() => {
    async function getInitial() {
      await getVideoList();
    }
    setMainState({ getCount: mainState.getCount + 1 });
    getInitial();
  }, [mainState.page, mainState.order, mainState.orderBy, forceLoad]);

  const header = [
    {
      id: "check",
      label: (
        <Checkbox
          color="primary"
          checked={mainState.checkAll}
          onChange={handleCheckAll}
        />
      ),
      disabledSort: true,
    },
    { id: "videoName", label: "Video Name" },
    { id: "streamtape", label: "Streamtape" },
    { id: "turbo", label: "Turbo" },
    { id: "doodstream", label: "DoodStream" },
    { id: "streamwish", label: "StreamWish" },
    { id: "status", label: "Sync" },
    { id: "createdAt", label: "Created At" },
    { id: "action", label: "", disabledSort: true },
  ];

  const body = generateBody(
    mainState.body,
    mainState.page,
    mainState.rowsPerPage,
    mainState.typeArr,
    handleDynamicCheck,
    hanldeEditDialog,
    classes
  );

  return (
    <Container className={classes.tableWrapper}>
      <Paper classes={{ root: classes.filterWrapper }}>
        <div
          className={clsx(
            classes.filterTitleWrapper,
            classes.flexCenter,
            classes.flexJustifyCenter
          )}
        >
          <Typography className={classes.title}>Streaming Resources</Typography>
        </div>

        <div className={classes.insertButtonGroupWrapper}>
          <Button
            className={clsx(classes.deleteButton, classes.insertButton)}
            variant="contained"
            color="primary"
            onClick={handleBulkDeleteDialog}
          >
            Delete Selected Data
          </Button>

          <Button
            className={classes.insertButton}
            variant="contained"
            color="primary"
            onClick={handleUpdateToVideoDialog}
          >
            Udpate to Video
          </Button>

          <Button
            className={classes.insertButton}
            variant="contained"
            color="primary"
            onClick={handleSyncCrawlerDialog}
          >
            Sync to Crawler List
          </Button>

          <Button
            className={classes.insertButton}
            variant="contained"
            color="primary"
            onClick={handleSyncDialog}
          >
            Sync With Platform
          </Button>

          <Button
            className={classes.insertButton}
            variant="contained"
            color="primary"
            onClick={handleCloneURLDialog}
          >
            Clone Uploaded Video URL
          </Button>
        </div>

        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item xs={12} md={6}>
            <TextField
              id="search-text"
              label="Search Video Name"
              fullWidth
              value={mainState.searchValue}
              onChange={handleChange("searchValue")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomSelect
              fullWidth
              label="Video Sync Status"
              stateKey="type"
              value={mainState.type}
              items={mainState.typeArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      </Paper>

      <CustomTable
        headCells={header}
        rows={body}
        page={mainState.page}
        pageItems={mainState.pageItems}
        rowsPerPage={mainState.rowsPerPage}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
      />

      <CustomDeleteDialog
        open={bulkDeleteDialog}
        setOpen={setBulkDeleteDialog}
        name={`${mainState.bulkSelectCount} items`}
        handleDelete={handleBulkDelete}
      />

      <AlertDialog
        open={cloneDialog}
        setOpen={setCloneDialog}
        handleConfirm={cloneVideoURL}
        title="Clone URL"
        content="Are you sure you want to clone new URL?"
      />

      <AlertDialog
        open={syncDialog}
        setOpen={setSyncDialog}
        handleConfirm={syncPlatform}
        title="Sync With Platform"
        content={`Are you sure you want to sync the selected video(totals ${mainState.bulkSelectCount}) to all platform(streamtape, turbo, doodstream, streamWish)? It won't upload to the platforms again if there is a record that was uploaded before.`}
      />

      <AlertDialog
        open={syncCrawlerDialog}
        setOpen={setSyncCrawlerDialog}
        handleConfirm={syncLocal}
        title="Sync To Crawler List"
        content={`Are you sure you want to sync the selected video(totals ${mainState.bulkSelectCount}) to all crawler list? It won't append the link if crawler list dont have the same fanhao.`}
      />

      <AlertDialog
        open={updateToVideoDialog}
        setOpen={setUpdateToVideoDialog}
        handleConfirm={updateToVideo}
        title="Update To Video List"
        content={`Are you sure you want to update the selected video(totals ${mainState.bulkSelectCount}) to video lit? It won't append the link if video list dont exists.`}
      />

      <EditDialog
        open={editDialog}
        setOpen={setEditDialog}
        items={mainState.dialogItems}
        setMainState={setMainState}
      />
    </Container>
  );
}

export default StreamingResource;
