import React, {Suspense} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Navbar from "./components/NavBar";

// lazy(() => import('./pages/account/Index'));
//const OtherComponent = React.lazy(() => import('./OtherComponent'));
// pages
// const Login = lazy(() => import('./pages/Login'));
// const Dashboard = lazy(() => import('./pages/Dashboard'));
// const Profile = lazy(() => import('./pages/Profile'));
// const VideoList = lazy(() => import('./pages/resources/Index'));
import Login from "./pages/Login";
import Dashboard from './pages/Dashboard';
import Profile from "./pages/Profile";
import VideoList from "./pages/resources/Index";
import CreateVideo from "./pages/resources/Create";
import EditVideo from "./pages/resources/Edit";
import Tags from "./pages/taxonomic/tags/Index";
import Models from "./pages/taxonomic/models/Index";
import Categories from "./pages/taxonomic/categories/Index";
import Studio from "./pages/taxonomic/studio/index.js";
import Headers from "./pages/webContent/headers/Index";
import Footers from './pages/webContent/footers/Index';
import VideoResources from "./pages/webContent/videoResources/Index";
import CrawlerResources from "./pages/crawlerResources/Index";
import PreEditVideoList from './pages/PreCrawlerResources/Index';
import EditCrawlerVideo from "./pages/crawlerResources/EditForm.js";
import StreamingResource from "./pages/stremingResources/Index";

const Fallback = () => <div>Loading...</div>;

// TODO: can add GA function in WithTracker
const wrapper = (Component) => (props) => {
  return <React.Fragment>
    {/*<WithTracker {...props}>*/}
    <Component {...props}/>
    {/*</WithTracker>*/}
  </React.Fragment>
};


function AdminRoutes() {
  return (
    <React.Fragment>
      <Navbar>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path="/admin/dashboard" render={wrapper(Dashboard)} />
            {/*<Route exact path="/" render={wrapper(Dashboard)} />*/}
            {/*<Route exact path="/edit" render={wrapper(Dashboard)} />*/}
            {/*<Route exact path="/create" render={wrapper(Dashboard)} />*/}
            {/*<Route component={Page404} />*/}
          </Switch>
        </Suspense>
      </Navbar>
    </React.Fragment>
  );
}

function ResourceRoutes() {
  return (
    <React.Fragment>
      <Navbar>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path="/videos" render={wrapper(VideoList)} />
            <Route exact path="/videos/create" render={wrapper(CreateVideo)} />
            <Route exact path="/videos/:resourceID/edit" render={wrapper(EditVideo)} />
            {/*<Route component={Page404} />*/}
          </Switch>
        </Suspense>
      </Navbar>
    </React.Fragment>
  );
}

function CrawlerResourceRoutes() {
  return (
    <React.Fragment>
      <Navbar>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path="/crawler" render={wrapper(CrawlerResources)} />
            <Route exact path="/crawler/prefill" render={wrapper(PreEditVideoList)} />
            <Route exact path="/crawler/prefill-url" render={wrapper(StreamingResource)} />
            {/*<Route component={Page404} />*/}
          </Switch>
        </Suspense>p
      </Navbar>
    </React.Fragment>
  );
}

function TaxonomicRoutes() {
  return (
    <React.Fragment>
      <Navbar>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route exact path="/taxonomic/studio" render={wrapper(Studio)} />
            <Route exact path="/taxonomic/tags" render={wrapper(Tags)} />
            <Route exact path="/taxonomic/categories" render={wrapper(Categories)} />
            <Route exact path="/taxonomic/models" render={wrapper(Models)} />
            {/*<Route component={Page404} />*/}
          </Switch>
        </Suspense>
      </Navbar>
    </React.Fragment>
  );
}

function ContentRoutes() {
  return (
    <React.Fragment>
      <Navbar>
          <Switch>
            <Route exact path="/content/footers" render={wrapper(Footers)} />
            <Route exact path="/content/headers" render={wrapper(Headers)} />
            <Route exact path="/content/video-resource" render={wrapper(VideoResources)} />
            {/*<Route exact path="/edit" render={wrapper(Dashboard)} />*/}
            {/*<Route exact path="/create" render={wrapper(Dashboard)} />*/}
            {/*<Route component={Page404} />*/}
          </Switch>
      </Navbar>
    </React.Fragment>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Switch>
          {/*Landing Page*/}
          <Route exact path="/" render={wrapper(Login)} />
          <Route path="/profile" render={wrapper(Profile)} />

          <Route path="/admin" render={AdminRoutes} />
          <Route path="/videos" render={ResourceRoutes} />
          <Route path="/crawler" render={CrawlerResourceRoutes} />
          <Route path="/taxonomic" render={TaxonomicRoutes} />
          <Route path="/content" render={ContentRoutes} />

        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}
export default Router;
