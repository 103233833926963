import React, {useContext, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import fetchData from '../utils/fetchData';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

import {utilsContext} from "../contexts/utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
    boxShadow: '0 12px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  tittleWrapper: {
    color: theme.palette.primary.contrastText,
    textTransform: 'none'
  },
  title: {
    margin: 10
  }
}));

export default function ToolbarDropMenu() {
  const classes = useStyles();
  const { _handleChange } = useContext(utilsContext);
  const [userName, setUserName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const anchorRef = React.useRef(null);


  useEffect(() => {
    const name = localStorage.getItem("userName");
    setUserName(name);
  }, []);


  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // API
  const logout = async events => {
    const options = {
      url: '/admin/logout',
      method: 'DELETE',
    };
    const result = await fetchData(options);
    const { success, descriptions } = result;

    if (success) {
      setRedirect(true)
    } else {
      _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: descriptions })
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      {redirect && <Redirect to="/" push/>}
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={classes.tittleWrapper}
        onClick={handleToggle}
      >
        <Typography className={classes.title} noWrap>
          Welcome {userName}
        </Typography>

        <FontAwesomeIcon icon={faUser}/>
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                  {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
