import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { utilsContext } from '../contexts/utils';


const useStyles = makeStyles(theme => ({
  special: {
    color: 'red',
    fontSize: 30
  },
  wrapper: {
    backgroundColor: theme.palette.secondary
  },
  sections: {
    backgroundColor: 'yellow'
  }
}));


function Profile() {
  const classes = useStyles();
  const { _handleChange, theme } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    value: 'Hello',
    value2: 'Wilson'
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <div>
      <p>Hello</p>
    </div>
  )
}

export default Profile;
