import React from "react";
import clsx from "clsx";

// Component

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    marginTop: 30,
  },
  sectionGroup: {
    display: "flex",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: 25,
  },
  innerSectionWrapper: {
    background: theme.palette.background.section,
    padding: 20,
    borderRadius: 10,
    marginTop: 20,
    position: "relative",
  },
  partWrapper: {
    backgroundColor: "#8e8e8e",
    padding: 20,
    borderRadius: 10,
    marginTop: 30,
    position: "relative",
  },
  partButton: {
    marginTop: 30,
    textAlign: "right",
  },
  columnFullWidth: {
    width: "100%",
  },
  floatIcon: {
    position: "absolute",
    top: -5,
    right: -4,
  },
  actionIcon: {
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  withSpacing: {
    marginLeft: 10,
  },
}));

const getLanguage = (index) => {
  let name;

  switch (index) {
    case 0:
      name = "Chinese";
      break;
    case 1:
      name = "English";
      break;
    case 2:
      name = "Japanese";
      break;
    default:
      name = "Chinese";
  }

  return name;
};

export default function EditFormDialog(props) {
  const { open, setOpen, items, sourceTypeOption, setMainState, handleUpdate } =
    props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key, subKey) => (event) => {
    const cpArr = { ...items };
    cpArr[key][subKey] = event.target.value;
    setMainState({ editItems: cpArr });
  };

  const handleChangeSingle = (key) => (event) => {
    const cpArr = { ...items };
    cpArr[key] = event.target.value;
    setMainState({ editItems: cpArr });
  };

  const handleChangeDynamic = (key, index, subKey, subIndex) => (event) => {
    const cpArr = { ...items };
    if (subIndex !== undefined) {
      cpArr[key][index][subIndex][subKey] = event.target.value;
    } else {
      cpArr[key][index][subKey] = event.target.value;
    }

    setMainState({ editItems: cpArr });
  };

  const handleChangeDownloadLink = (index, subIndex, arrayIndex) => (event) => {
    const cpArr = { ...items };
    cpArr.embedLink[index][subIndex].downloadLink[arrayIndex] =
      event.target.value;
    setMainState({ editItems: cpArr });
  };

  const handleSectionAddLinkItem = (key) => () => {
    const cpArr = { ...items };
    cpArr[key].push([{ sourceID: "", value: "", downloadLink: [""] }]);
    setMainState({ editItems: cpArr });
  };

  const handleSectionDeleteLinkItem = (index) => () => {
    const cpArr = { ...items };
    cpArr.embedLink.splice(index, 1);
    setMainState({ editItems: cpArr });
  };

  const handleSectionAddItem = (key) => () => {
    const cpArr = { ...items };
    cpArr[key].push({ cn: "", en: "", jp: "" });
    setMainState({ editItems: cpArr });
  };

  const handleSectionDeleteItem = (key, index) => () => {
    const cpArr = { ...items };
    cpArr[key].splice(index, 1);
    setMainState({ editItems: cpArr });
  };

  const handleAddPart = (index) => () => {
    const cpArr = { ...items };
    cpArr.embedLink[index].push({
      sourceID: "",
      value: "",
      downloadLink: [""],
    });
    setMainState({ editItems: cpArr });
  };

  const handleDeletePart = (index, subIndex) => () => {
    const cpArr = { ...items };
    cpArr.embedLink[index].splice(subIndex, 1);
    setMainState({ editItems: cpArr });
  };

  const handleAddDownloadLink = (index, subIndex) => () => {
    const cpArr = { ...items };
    cpArr.embedLink[index][subIndex].downloadLink.push("");
    setMainState({ editItems: cpArr });
  };

  const handleDeleteDownloadLink = (index, subIndex, arrayIndex) => () => {
    const cpArr = { ...items };
    cpArr.embedLink[index][subIndex].downloadLink.splice(arrayIndex, 1);
    setMainState({ editItems: cpArr });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Edit Videos:</DialogTitle>
        <DialogContent>
          <Typography className={classes.sectionTitle}>Details</Typography>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            className={classes.inputWrapper}
          >
            <Grid item xs={12} md={4}>
              <TextField
                id="video-name-cn"
                fullWidth
                label="Video Chinese Name"
                multiline
                value={items.title.cn}
                onChange={handleChange("title", "cn")}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="video-name-cn"
                fullWidth
                label="Video English Name"
                multiline
                value={items.title.en}
                onChange={handleChange("title", "en")}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                id="video-name-jp"
                fullWidth
                label="Video Japanese Name"
                multiline
                value={items.title.jp}
                onChange={handleChange("title", "jp")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id="video-thumbnail"
                fullWidth
                label="Video Thumbnail"
                value={items.imgSrc}
                onChange={handleChangeSingle("imgSrc")}
              />
            </Grid>

            {/*{(items.videoType !== 1 && items.videoType !== 4) &&*/}
            <Grid item xs={12} md={6}>
              <TextField
                id="video-trailer"
                fullWidth
                label="Video Trailer"
                value={items.trailer}
                onChange={handleChangeSingle("trailer")}
              />
            </Grid>
            {/*}*/}
          </Grid>

          <Paper className={classes.sectionWrapper}>
            <div className={classes.sectionGroup}>
              <Typography className={classes.sectionTitle}>
                Embed Link/Download Link
              </Typography>
              <IconButton
                aria-label="add"
                onClick={handleSectionAddLinkItem("embedLink")}
              >
                <AddCircleIcon />
              </IconButton>
            </div>

            {items.embedLink.map((item, index) => (
              <div className={classes.innerSectionWrapper}>
                {item.map((arrayItem, arrayIndex) => (
                  <div
                    key={`section-${index}-paper-${arrayIndex}`}
                    className={classes.partWrapper}
                  >
                    <FormControl className={clsx(classes.columnFullWidth)}>
                      <InputLabel id={`source-select-${index}`}>
                        Video Source
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id={`part-source-controlled-open-select-${index}`}
                        value={arrayItem.sourceID}
                        onChange={handleChangeDynamic(
                          "embedLink",
                          index,
                          "sourceID",
                          arrayIndex
                        )}
                      >
                        {sourceTypeOption.map((d) => (
                          <MenuItem key={d.label} value={d.value}>
                            {d.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      id={`video-link-${index}-embedLink-${arrayIndex}`}
                      fullWidth
                      label={`Embed Link ${arrayIndex + 1}`}
                      value={arrayItem.value}
                      onChange={handleChangeDynamic(
                        "embedLink",
                        index,
                        "value",
                        arrayIndex
                      )}
                    />

                    {arrayItem.downloadLink.map(
                      (downloadItem, downloadIndex) => (
                        <Grid
                          key={`download-link-${index}-embedLink-${arrayIndex}-${downloadIndex}`}
                          container
                          alignItems="flex-end"
                        >
                          <Grid item xs={8}>
                            <TextField
                              id={`download-link-${index}-embedLink-${arrayIndex}-${downloadIndex}`}
                              fullWidth
                              label={`Download Link ${downloadIndex + 1}`}
                              value={downloadItem}
                              onChange={handleChangeDownloadLink(
                                index,
                                arrayIndex,
                                downloadIndex
                              )}
                            />
                          </Grid>

                          <Grid item xs={1}>
                            {downloadIndex ===
                              arrayItem.downloadLink.length - 1 && (
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                className={clsx(
                                  classes.actionIcon,
                                  classes.withSpacing
                                )}
                                onClick={handleAddDownloadLink(
                                  index,
                                  arrayIndex
                                )}
                              />
                            )}
                            {downloadIndex !== 0 && (
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className={clsx(
                                  classes.actionIcon,
                                  classes.withSpacing
                                )}
                                onClick={handleDeleteDownloadLink(
                                  index,
                                  arrayIndex,
                                  downloadIndex
                                )}
                              />
                            )}
                          </Grid>
                        </Grid>
                      )
                    )}

                    {arrayIndex !== 0 && (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className={clsx(classes.actionIcon, classes.floatIcon)}
                        onClick={handleDeletePart(index, arrayIndex)}
                      />
                    )}
                  </div>
                ))}

                <div className={classes.partButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPart(index)}
                  >
                    Add Part
                  </Button>
                </div>

                {index !== 0 && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className={clsx(classes.actionIcon, classes.floatIcon)}
                    onClick={handleSectionDeleteLinkItem(index)}
                  />
                )}
              </div>
            ))}
          </Paper>

          <Paper className={classes.sectionWrapper}>
            <div className={classes.sectionGroup}>
              <Typography className={classes.sectionTitle}>Models</Typography>
              <IconButton
                aria-label="add"
                onClick={handleSectionAddItem("models")}
              >
                <AddCircleIcon />
              </IconButton>
            </div>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              {items.models.map((model, index) => (
                <React.Fragment>
                  {Object.keys(model).map((key, objIndex) => (
                    <Grid item xs={3}>
                      <TextField
                        id={`video-model-chinese-${key}`}
                        fullWidth
                        label={`${getLanguage(objIndex)} Name`}
                        value={model[key]}
                        onChange={handleChangeDynamic("models", index, key)}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={3}>
                    <IconButton
                      aria-label="add"
                      onClick={handleSectionDeleteItem("models", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>

          <Paper className={classes.sectionWrapper}>
            <div className={classes.sectionGroup}>
              <Typography className={classes.sectionTitle}>Studios</Typography>
              <IconButton
                aria-label="add"
                onClick={handleSectionAddItem("studios")}
              >
                <AddCircleIcon />
              </IconButton>
            </div>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              {items.studios.map((studio, index) => (
                <React.Fragment>
                  {Object.keys(studio).map((key, objIndex) => (
                    <Grid item xs={3}>
                      <TextField
                        id={`video-studio-chinese-${key}`}
                        fullWidth
                        label={`${getLanguage(objIndex)} Name`}
                        value={studio[key]}
                        onChange={handleChangeDynamic("studios", index, key)}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={3}>
                    <IconButton
                      aria-label="add"
                      onClick={handleSectionDeleteItem("studios", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>

          <Paper className={classes.sectionWrapper}>
            <div className={classes.sectionGroup}>
              <Typography className={classes.sectionTitle}>Tags</Typography>
              <IconButton
                aria-label="add"
                onClick={handleSectionAddItem("tags")}
              >
                <AddCircleIcon />
              </IconButton>
            </div>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              className={classes.inputWrapper}
            >
              {items.tags.map((tag, index) => (
                <React.Fragment>
                  {Object.keys(tag).map((key, objIndex) => (
                    <Grid item xs={3}>
                      <TextField
                        id={`video-model-chinese-${key}`}
                        fullWidth
                        label={`${getLanguage(objIndex)} Name`}
                        value={tag[key]}
                        onChange={handleChangeDynamic("tags", index, key)}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={3}>
                    <IconButton
                      aria-label="add"
                      onClick={handleSectionDeleteItem("tags", index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
