import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import ReactPlayer from "react-player";
import moment from "moment";

// utils
import { utilsContext } from "../../contexts/utils";
import { FILE_UPLOAD_SIZE } from "../../configs/constents";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// materia ui
import {
  Container,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";

// components
import SuggestSelect from "../../components/SugguestSelect";
import resources from "../../api/resources";
import LinkArrBox from "./LinkArrBox";
import CustomCheckBox from "../../components/CustomCheckBox";

const useStyles = makeStyles((theme) => ({
  // wrapper
  wrapper: {
    marginBottom: 40,
  },
  titleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: "fit-content",
    borderRadius: 10,
    position: "absolute",
  },
  formWrapper: {
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 30,
    },
  },
  dynamicInputWrapper: {
    padding: "20px 40px",
  },
  dynamicItem: {
    backgroundColor: theme.palette.background.section,
    boxShadow: "3px 4px 25px 0px rgba(199,199,199,1);",
    borderRadius: 10,
    position: "relative",
    marginTop: 20,
    minWidth: 360,
  },
  closeIconWrapper: {
    position: "absolute",
    top: -8,
    right: -4,
  },
  dynamicAdd: {
    position: "relative",
    cursor: "pointer",
    minHeight: 184,
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "end",
  },
  relativeWrapper: {
    position: "relative",
  },
  // text
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
    padding: theme.spacing(0, 3),
  },
  textField: {
    width: "100%",
  },
  closeIcon: {
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  addIcon: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    marginTop: -50,
    height: 100,
    width: "40px !important",
    left: "50%",
    marginLeft: -20,
  },
  inputFile: {},
  inputFileText: {},
  // elements
  uploadButton: {
    marginRight: 15,
  },
  cancelButton: {
    marginRight: 15,
    backgroundColor: "red",
  },
  videoFlame: {
    display: "none",
  },
  thumbnail: {
    width: 300,
  },
  // common
  spacing: {
    marginTop: 20,
  },
  columnSplit: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  columnHalfWidth: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  columnFullWidth: {
    width: "100%",
    marginTop: 10,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexBaseLine: {
    display: "flex",
    alignItems: "baseline",
  },
  flexAlignBottom: {
    alignItems: "flex-end",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

function EditVideo(props) {
  const classes = useStyles();
  const { resourceID } = useParams();
  const { _handleChange } = useContext(utilsContext);
  const [mainState, _setMainState] = useState({
    videoName: "",
    videoJpName: "",
    videoCnName: "",
    trailer: "",
    cnSubCheck: false,
    enSubCheck: false,
    multiInput: "",
    multiSelectedItem: [],
    tags: [{ value: "", label: "" }],
    multiStudioInput: "",
    multiStudioSelectedItem: [],
    studios: [{ value: "", label: "" }],
    multiModelInput: "",
    multiModelSelectedItem: [],
    models: [{ value: "", label: "" }],
    category: "",
    categoryOpen: false,
    categoryOption: [{ value: "", label: "None" }],
    videoResourceTypeOption: [{ value: "", label: "None" }],
    videoSourceOption: [
      { value: "LINK", label: "Link" },
      { value: "EMBED", label: "Embed" },
    ],
    defaultLinkArrValue: { partLink: "", downloadLink: [""] },
    defaultVideoValue: {
      linkArr: [{ sourceType: "", partLink: "", downloadLink: [""] }],
      source: "EMBED",
      length: "",
      videoSourceOpen: false,
    },
    videos: [
      {
        linkArr: [{ sourceType: "", partLink: "", downloadLink: [""] }],
        source: "EMBED",
        length: "",
        videoSourceOpen: false,
      },
    ],
    photoName: "",
    initialFile: "",
    thumbnail: "",
  });

  useEffect(() => {
    async function initialize() {
      _handleChange({ openBackdrop: true });
      getStudio();
      getTags();
      getModels();
      getDetails();
      getVideoResourceType();
      await getCategory();
      _handleChange({ openBackdrop: false });
    }
    // Execute the created function directly
    initialize();
  }, []);

  // common function
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleToggle = (key) => (event) => {
    setMainState({ [key]: !mainState[key] });
  };

  const handleClose = (key) => () => {
    setMainState({ [key]: false });
  };

  const handleOpen = (key) => () => {
    setMainState({ [key]: true });
  };

  // Function for suggestSelect area
  const handleKeyDownMUL = (key) => (event) => {
    const {
      multiSelectedItem,
      multiInput,
      multiModelSelectedItem,
      multiModelInput,
      multiStudioSelectedItem,
      multiStudioInput,
    } = mainState;

    if (key === "multiInput") {
      if (
        multiSelectedItem.length &&
        !multiInput.length &&
        event.key === "Backspace"
      ) {
        setMainState({
          multiSelectedItem: [...multiSelectedItem].slice(
            0,
            multiSelectedItem.length - 1
          ),
        });
      }
    } else if (key === "multiModelInput") {
      if (
        multiModelSelectedItem.length &&
        !multiModelInput.length &&
        event.key === "Backspace"
      ) {
        setMainState({
          multiModelSelectedItem: [...multiModelSelectedItem].slice(
            0,
            multiModelSelectedItem.length - 1
          ),
        });
      }
    } else if (key === "multiStudioInput") {
      if (
        multiStudioSelectedItem.length &&
        !multiStudioInput.length &&
        event.key === "Backspace"
      ) {
        setMainState({
          multiStudioSelectedItem: [...multiStudioSelectedItem].slice(
            0,
            multiStudioSelectedItem.length - 1
          ),
        });
      }
    }
  };

  const handleInputChangeMUL = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleChangeMUL = (key) => (item) => {
    const {
      multiSelectedItem,
      multiModelSelectedItem,
      multiStudioSelectedItem,
    } = mainState;

    let newSelectedItem = [],
      newInputKey;
    const newCompareValue =
      key === "multiSelectedItem"
        ? multiSelectedItem
        : key === "multiModelSelectedItem"
        ? multiModelSelectedItem
        : multiStudioSelectedItem;

    if (key === "multiSelectedItem") {
      newSelectedItem = [...multiSelectedItem];
      newInputKey = "multiInput";
    } else if (key === "multiModelSelectedItem") {
      newSelectedItem = [...multiModelSelectedItem];
      newInputKey = "multiModelInput";
    } else if (key === "multiStudioSelectedItem") {
      newSelectedItem = [...multiStudioSelectedItem];
      newInputKey = "multiStudioInput";
    }

    if (newCompareValue.indexOf(item) === -1) {
      newSelectedItem = [...newCompareValue, item];
    }

    setMainState({
      [key]: newSelectedItem,
      [newInputKey]: "",
    });
  };

  const handleDelete = (item, key) => () => {
    const {
      multiSelectedItem,
      multiModelSelectedItem,
      multiStudioSelectedItem,
    } = mainState;
    let newSelectedItem =
      key === "multiInput"
        ? [...multiSelectedItem]
        : key === "multiModelInput"
        ? [...multiModelSelectedItem]
        : [...multiStudioSelectedItem];
    const changeArrKey =
      key === "multiInput"
        ? "multiSelectedItem"
        : key === "multiModelInput"
        ? "multiModelSelectedItem"
        : "multiStudioSelectedItem";

    console.log("item", item);
    console.log("changeArrKey", changeArrKey);
    console.log("newSelectedItem", newSelectedItem);
    console.log("newSelectedItem.indexOf(item)", newSelectedItem.indexOf(item));
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    console.log("newSelectedItem", newSelectedItem);
    setMainState({ [changeArrKey]: newSelectedItem });
  };

  const handleDeleteThumbnail = () => {
    setMainState({ thumbnail: "" });
  };

  // Custom functions
  const handleOnDrop = (files) => {
    if (!files.length) return;

    setMainState({ photoName: files[0].name });
  };

  const handleDropRejected = (files) => {
    if (!files.length) return;
    const file = files[0];
    if (file.size >= FILE_UPLOAD_SIZE) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "The chosen file is too large (5MB maximum)",
      });
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    accept: "image/*",
    multiple: false,
    maxSize: FILE_UPLOAD_SIZE,
  });

  // API functions
  const getVideoResourceType = async () => {
    const result = await resources.getAllVideoResources();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get video resource",
      });
    }

    setMainState({
      videoResourceTypeOption: data.map((e) => ({
        value: e.ID,
        label: e.name,
      })),
    });
  };

  const getCategory = async () => {
    const result = await resources.getCategories();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get categories",
      });
    }

    let categoryOption = [...mainState.categoryOption];
    data.forEach((e) => {
      categoryOption.push({ value: e.ID, label: e.name });
    });

    setMainState({ categoryOption });
  };

  const getTags = async () => {
    const result = await resources.getTags();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get categories",
      });
    }

    let tags = [...mainState.categoryOption];
    data.forEach((e) => {
      tags.push({
        value: e.ID,
        label: e.name || e.cnName || e.jpName || "",
      });
    });

    setMainState({ tags });
  };

  const getModels = async () => {
    const result = await resources.getModels();
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get models",
      });
    }

    const models = data.map((e) => ({
      value: e.ID,
      label: e.name || e.cnName || e.jpName || "",
    }));

    setMainState({ models });
  };

  const getStudio = async () => {
    const result = await resources.getStudio();
    const { data, success } = result;
    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Fail to get studio",
      });
    }
    setMainState({
      studios: data.map((e) => ({ value: e.ID, label: e.name })),
    });
  };

  const getDetails = async () => {
    const result = await resources.getTheVideo(resourceID);
    const { data, success } = result;

    if (!success) {
      return _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: data.message,
      });
    }
    const details = data;

    let videos = details.videoList.map((e) => ({
      linkArr: e.linkArr,
      source: e.videoSource,
      length: e.videoLength,
      videoSourceOpen: false,
    }));

    console.log("details.tags", details.tags);
    setMainState({
      videoName: details.resourceName,
      videoJpName: details.jpName,
      videoCnName: details.cnName,
      cnSubCheck: !!details.cnSub,
      enSubCheck: !!details.enSub,
      multiSelectedItem: details.tags,
      multiModelSelectedItem: details.models,
      multiStudioSelectedItem: details.studios,
      category: details.categories[0],
      videos,
      trailer: details.trailer,
      thumbnail: details.imgPath,
    });
  };

  const updateForm = async () => {
    const {
      videoName,
      videoJpName,
      videoCnName,
      trailer,
      cnSubCheck,
      enSubCheck,
      multiSelectedItem,
      multiModelSelectedItem,
      multiStudioSelectedItem,
      category,
      videos,
    } = mainState;

    _handleChange({ openBackdrop: true });

    const formData = new FormData();
    formData.append("resourceName", videoName);
    formData.append("videoCnName", videoJpName);
    formData.append("videoJpName", videoJpName);
    formData.append("cnSubCheck", cnSubCheck);
    formData.append("enSubCheck", enSubCheck);
    formData.append("subtitles", cnSubCheck ? "CN" : enSubCheck ? "EN" : "");
    formData.append("trailer", trailer);
    formData.append("categories", category);
    formData.append("tags", JSON.stringify(multiSelectedItem));
    formData.append("models", JSON.stringify(multiModelSelectedItem));
    formData.append("studios", JSON.stringify(multiStudioSelectedItem));
    formData.append("videosLink", JSON.stringify(videos));
    formData.append("file", acceptedFiles[0]);

    const result = await resources.updateVideo(formData, resourceID);
    const { success, data } = result;

    let snackbarContent = "Update video successful";
    let snackbarType = 0;
    if (!success) {
      snackbarContent = data.message;
      snackbarType = 1;
    }
    _handleChange({
      openBackdrop: false,
      snackbar: true,
      snackbarType,
      snackbarContent,
    });
  };

  const backPage = () => {
    props.history.goBack();
  };

  return (
    <Container>
      <Paper classes={{ root: classes.wrapper }}>
        <div
          className={clsx(
            classes.titleWrapper,
            classes.flexCenter,
            classes.flexJustifyCenter
          )}
        >
          <Typography className={classes.title}>Edit Videos</Typography>
        </div>

        <div className={classes.formWrapper}>
          <div className={clsx(classes.columnSplit)}>
            <TextField
              id="video-name"
              className={classes.columnFullWidth}
              label="Video Name"
              multiline
              value={mainState.videoName}
              onChange={handleChange("videoName")}
            />

            <TextField
              id="video-name-jp"
              className={classes.columnFullWidth}
              label="Video Japanese Name"
              multiline
              value={mainState.videoJpName}
              onChange={handleChange("videoJpName")}
            />

            {/*<TextField*/}
            {/*  id="video-name-cn"*/}
            {/*  className={classes.columnFullWidth}*/}
            {/*  label="Video Chinese Name"*/}
            {/*  multiline*/}
            {/*  value={mainState.videoCnName}*/}
            {/*  onChange={handleChange('videoCnName')}*/}
            {/*/>*/}

            <FormControl className={clsx(classes.columnFullWidth)}>
              <InputLabel id="category-option-select">Category</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={mainState.categoryOpen}
                onClose={handleClose("categoryOpen")}
                onOpen={handleOpen("categoryOpen")}
                value={mainState.category}
                onChange={handleChange("category")}
              >
                {mainState.categoryOption.map((e) => (
                  <MenuItem key={e.label} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <LinkArrBox
            videos={mainState.videos}
            setMainState={setMainState}
            defaultLinkArrValue={mainState.defaultLinkArrValue}
            defaultVideoValue={mainState.defaultVideoValue}
            sourceOption={mainState.videoSourceOption}
            sourceTypeOption={mainState.videoResourceTypeOption}
          />

          <div
            className={clsx(
              classes.columnSplit,
              classes.flexBaseLine,
              classes.flexJustifySpaceBetween,
              classes.spacing
            )}
          >
            <div>
              <Typography>Thumbnail</Typography>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
              </div>

              {/* Show when no image */}
              {mainState.thumbnail ? (
                <div className={classes.relativeWrapper}>
                  <img
                    className={classes.thumbnail}
                    src={mainState.thumbnail}
                    alt="thumbnail"
                  />
                  <span
                    className={classes.closeIconWrapper}
                    onClick={handleDeleteThumbnail}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={classes.closeIcon}
                    />
                  </span>
                </div>
              ) : (
                <Grid container justify="space-between" alignItems="center">
                  <Button
                    className={classes.uploadButton}
                    color="primary"
                    variant="contained"
                    onClick={open}
                  >
                    Choose a file
                  </Button>
                  <Typography className={classes.uploadText}>
                    {mainState.photoName || "No file chosen"}
                  </Typography>
                </Grid>
              )}
            </div>

            <TextField
              id="video-trailer"
              className={clsx(classes.columnHalfWidth, classes.spacing)}
              label="Trailer URL"
              value={mainState.trailer}
              onChange={handleChange("trailer")}
            />
          </div>

          <div className={classes.spacing}>
            <SuggestSelect
              label="Tags"
              placeholder="Select multiple tags"
              input={mainState.multiInput}
              suggestions={mainState.tags}
              selectedItem={mainState.multiSelectedItem}
              mainKey="multiInput"
              handleKeyDown={handleKeyDownMUL}
              handleInputChange={handleInputChangeMUL}
              handleChange={handleChangeMUL("multiSelectedItem")}
              handleDelete={handleDelete}
            />
          </div>

          <div className={classes.spacing}>
            <SuggestSelect
              label="Models"
              placeholder="Select multiple models"
              input={mainState.multiModelInput}
              suggestions={mainState.models}
              selectedItem={mainState.multiModelSelectedItem}
              mainKey="multiModelInput"
              handleKeyDown={handleKeyDownMUL}
              handleInputChange={handleInputChangeMUL}
              handleChange={handleChangeMUL("multiModelSelectedItem")}
              handleDelete={handleDelete}
            />
          </div>

          <div className={classes.spacing}>
            <SuggestSelect
              label="Studio"
              placeholder="Select multiple studio"
              input={mainState.multiStudioInput}
              suggestions={mainState.studios}
              selectedItem={mainState.multiStudioSelectedItem}
              mainKey="multiStudioInput"
              handleKeyDown={handleKeyDownMUL}
              handleInputChange={handleInputChangeMUL}
              handleChange={handleChangeMUL("multiStudioSelectedItem")}
              handleDelete={handleDelete}
            />
          </div>

          <div
            className={clsx(
              classes.buttonWrapper,
              classes.spacing,
              classes.flexCenter,
              classes.flexJustifySpaceBetween
            )}
          >
            <div>
              <CustomCheckBox
                label="Chinese Sub"
                handleToggle={handleToggle}
                changeKey="cnSubCheck"
                value={mainState.cnSubCheck}
              />
              <CustomCheckBox
                label="English Sub"
                handleToggle={handleToggle}
                changeKey="enSubCheck"
                value={mainState.enSubCheck}
              />
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={backPage}
              >
                Back
              </Button>

              <Button variant="contained" color="primary" onClick={updateForm}>
                UPDATE
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </Container>
  );
}

export default EditVideo;
