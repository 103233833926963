import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// Material UI
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
// Component
import resources from "../../api/resources.js";
import { utilsContext } from "../../contexts/utils";
import useQuery from "../../hooks/useQuery";
import CustomSelect from "../../components/CustomSelect";
import CustomTable from "../../components/CustomTable";
import FormDialog from "./FormDialog";
import CreateFormDialog from "./CreateFormDialog";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    padding: 0,
  },
  filterWrapper: {
    display: "grid",
    padding: 0,
    marginBottom: 40,
  },
  filterTitleWrapper: {
    margin: "-25px 15px 0",
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    width: 155,
    borderRadius: 10,
  },
  formContainer: {
    padding: "15px 30px",
  },
  title: {
    fontSize: theme.palette.primary.fontSize,
    color: theme.palette.secondary.main,
  },
  insertButtonGroupWrapper: {
    textAlign: "end",
    margin: 0,
    left: "25%",
  },
  insertButton: {
    marginRight: 15,
  },
  searchButton: {
    borderRadius: "0 0 5px 5px",
    width: "100%",
    marginTop: 30,
  },
  deleteButton: {
    backgroundColor: "#f75145",
    color: theme.palette.primary.contrastText,
  },
  // common
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexJustifyCenter: {
    justifyContent: "center",
  },
  flexJustifySpaceEvenly: {
    justifyContent: "space-evenly",
  },
  flexJustifySpaceBetween: {
    justifyContent: "space-between",
  },
}));

const getCategoryName = (arr, id) => {
  let name;
  arr.forEach((e) => {
    if (e.value === id) name = e.label;
  });
  return name;
};

const generateBody = (
  items,
  page,
  rowsPerPage,
  categoryOption,
  handleCheck,
  handleEditVideos,
  handleDelete,
  classes
) => {
  return items.map((e, i) => ({
    check: (
      <Checkbox color="primary" onChange={handleCheck(i)} checked={e.checked} />
    ),
    cnName: e.name,
    category: getCategoryName(categoryOption, e.category),
    createdAt: e.createdAt,
    action: (
      <div className={clsx(classes.flexCenter, classes.flexJustifySpaceEvenly)}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditVideos(e)}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.deleteButton}
          onClick={handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    ),
  }));
};

const defaultDialogItem = {
  type: 0,
  ID: "",
  name: "",
  videoSource: "",
  category: 0,
};
const defaultCreateDialogItem = { name: "", videoSource: "" };

function PreEditVideoList() {
  const classes = useStyles();
  const [query, setQuery] = useQuery();
  const { _handleChange } = useContext(utilsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [forceLoad, setForceLoad] = useState(false);
  const [mainState, _setMainState] = useState({
    page: query.offset ? parseInt(query.offset) : 1,
    pageItems: 15,
    rowsPerPage: 20,
    order: "desc",
    orderBy: "createdAt",
    checkAll: false,
    searchValue: "",
    type: 0,
    typeArr: [
      { label: "All", value: 0 },
      { label: "Mosaic-Removed", value: 1 },
      { label: "Censored", value: 2 },
      { label: "Chinese Sub", value: 3 },
      { label: "Uncensored", value: 5 },
    ],
    body: [],
    createDialogCategory: 0,
    createDialogItem: [{ ...defaultCreateDialogItem }],
    dialogItems: { ...defaultDialogItem },
    getCount: 0,
    deleteItems: {
      ID: "",
      name: "",
    },
    bulkDeleteCount: 0,
  });

  // common functions
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleCreateDialog = () => {
    const { createDialogItem } = mainState;
    const items =
      createDialogItem.length > 1
        ? createDialogItem
        : [{ ...defaultCreateDialogItem }];
    setMainState({ createDialogItem: items });
    setOpenCreateDialog(true);
  };

  const handleEditDialog = (item) => () => {
    setMainState({ dialogItems: { ...item } });
    setOpenDialog(true);
  };

  const handleDeleteDialog = (item) => () => {
    setMainState({ deleteItems: item });
    setDeleteDialog(true);
  };

  const handleBulkDeleteDialog = () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);
    setMainState({ bulkDeleteCount: selectedList.length });
    setBulkDeleteDialog(true);
  };

  const handleDynamicCheck = (index) => (event) => {
    const tmpBody = [...mainState.body];
    tmpBody[index].checked = event.target.checked;
    setMainState({ body: tmpBody, checkAll: false });
  };

  const handleCheckAll = (event) => {
    const tmpBody = [...mainState.body];
    tmpBody.forEach((e) => {
      e.checked = event.target.checked;
    });
    setMainState({ body: tmpBody, checkAll: event.target.checked });
  };

  // API
  const getPrefillList = async (searchPage) => {
    _handleChange({ openBackdrop: true });

    const { page, rowsPerPage, order, orderBy, type, getCount } = mainState;
    const params = {
      page: searchPage ? 1 : page,
      limit: rowsPerPage,
      sort: orderBy,
      sortBy: order,
      category: type === 0 ? null : type,
      keyword: searchPage === "" ? null : searchPage,
    };

    const { success, data } = await resources.getPrefillList(params);
    const failureObj = {};

    if (success) {
      const tmpArr = data.data.map((e) => ({
        ID: e.ID,
        category: e.category,
        name: e.name,
        createdAt: e.createdAt,
        checked: false,
        videoSource: e.videoSource,
      }));
      setMainState({ body: tmpArr, pageItems: data.total });
    } else {
      failureObj.snackbar = true;
      failureObj.snackbarType = 1;
      failureObj.snackbarContent = "Fail to get list";
    }

    if (getCount !== 0) setQuery(params);
    _handleChange({ openBackdrop: false, ...failureObj });
  };

  const handleSearch = async () => {
    const { searchValue } = mainState;
    await getPrefillList(searchValue);
  };

  const handleCreate = async () => {
    const { createDialogCategory, createDialogItem } = mainState;

    let validation = false;

    if (createDialogCategory === 0) {
      validation = "Please Select Category";
    }

    createDialogItem.forEach((e) => {
      if (!e.name) validation = "Name cannot be blank";
    });

    if (validation) {
      _handleChange({
        openBackdrop: false,
        snackbar: true,
        snackbarType: 2,
        snackbarContent: validation,
      });
      return;
    }

    _handleChange({ openBackdrop: true });

    const { success } = await resources.createPrefill({
      items: createDialogItem,
      category: createDialogCategory,
    });

    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Create Successfully";
      setOpenCreateDialog(false);
      setMainState({ createDialogItem: [{ ...defaultCreateDialogItem }] });
    } else {
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Something went wrong";
    }

    setForceLoad(!forceLoad);
    _handleChange({ openBackdrop: false, snackbar: true, ...snackbarObj });
  };

  const handleUpdate = async () => {
    const { dialogItems } = mainState;

    let validation = false;

    if (dialogItems.category === 0) {
      validation = "Please Select Category";
    } else if (dialogItems.name === "") {
      validation = "Name cannot be blank";
    }

    if (validation) {
      _handleChange({
        openBackdrop: false,
        snackbar: true,
        snackbarType: 2,
        snackbarContent: validation,
      });
      return;
    }

    _handleChange({ openBackdrop: true });

    const { success } = await resources.updatePrefill(
      {
        name: dialogItems.name,
        videoSource: dialogItems.videoSource,
        category: dialogItems.category,
      },
      dialogItems.ID
    );

    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Update Successfully";
      setOpenDialog(false);
    } else {
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Something went wrong";
    }

    setForceLoad(!forceLoad);
    _handleChange({ openBackdrop: false, snackbar: true, ...snackbarObj });
  };

  const handleDelete = async () => {
    const { deleteItems } = mainState;

    _handleChange({ openBackdrop: true });

    const { success } = await resources.deletePrefill(deleteItems.ID);
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Deleted";
      setDeleteDialog(false);
    } else {
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Something went wrong";
    }

    setForceLoad(!forceLoad);
    _handleChange({ openBackdrop: false, snackbar: true, ...snackbarObj });
  };

  const handleBulkDelete = async () => {
    const { body } = mainState;
    const selectedList = body.filter((e) => e.checked);

    if (!selectedList.length) {
      _handleChange({
        snackbar: true,
        snackbarType: 2,
        snackbarContent: "No data selected",
      });
      return;
    }

    const items = selectedList.map((e) => e.ID);

    const { success } = await resources.bulkDeletePrefill({ items });
    const snackbarObj = {};

    if (success) {
      snackbarObj.snackbarType = 0;
      snackbarObj.snackbarContent = "Successfully Deleted";
      setBulkDeleteDialog(false);
    } else {
      snackbarObj.snackbarType = 1;
      snackbarObj.snackbarContent = "Something went wrong";
    }

    setMainState({ checkAll: false });
    setForceLoad(!forceLoad);
    _handleChange({ openBackdrop: false, snackbar: true, ...snackbarObj });
  };

  useEffect(() => {
    async function getInitial() {
      await getPrefillList();
    }
    setMainState({ getCount: mainState.getCount + 1 });
    getInitial();
  }, [mainState.page, mainState.order, mainState.orderBy, forceLoad]);

  const header = [
    {
      id: "check",
      label: (
        <Checkbox
          color="primary"
          checked={mainState.checkAll}
          onChange={handleCheckAll}
        />
      ),
      disabledSort: true,
    },
    { id: "cnName", label: "Video Name" },
    { id: "category", label: "Categories" },
    { id: "createdAt", label: "Created At" },
    { id: "action", label: "Action", disabledSort: true },
  ];

  const body = generateBody(
    mainState.body,
    mainState.page,
    mainState.rowsPerPage,
    mainState.typeArr,
    handleDynamicCheck,
    handleEditDialog,
    handleDeleteDialog,
    classes
  );

  return (
    <Container className={classes.tableWrapper}>
      <FormDialog
        open={openDialog}
        setOpen={setOpenDialog}
        items={mainState.dialogItems}
        categories={mainState.typeArr}
        setMainState={setMainState}
        handleSubmit={handleUpdate}
      />

      <CreateFormDialog
        open={openCreateDialog}
        setOpen={setOpenCreateDialog}
        items={mainState.createDialogItem}
        defaultItem={{ ...defaultCreateDialogItem }}
        categories={mainState.typeArr}
        category={mainState.createDialogCategory}
        setMainState={setMainState}
        handleSubmit={handleCreate}
      />

      <CustomDeleteDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        name={mainState.deleteItems.name}
        handleDelete={handleDelete}
      />

      <CustomDeleteDialog
        open={bulkDeleteDialog}
        setOpen={setBulkDeleteDialog}
        name={`${mainState.bulkDeleteCount} items`}
        handleDelete={handleBulkDelete}
      />

      <Paper classes={{ root: classes.filterWrapper }}>
        <div
          className={clsx(
            classes.filterTitleWrapper,
            classes.flexCenter,
            classes.flexJustifyCenter
          )}
        >
          <Typography className={classes.title}>Pre Fill</Typography>
        </div>

        <div className={classes.insertButtonGroupWrapper}>
          <Button
            className={clsx(classes.deleteButton, classes.insertButton)}
            variant="contained"
            color="primary"
            onClick={handleBulkDeleteDialog}
          >
            Delete Selected Data
          </Button>

          <Button
            className={classes.insertButton}
            variant="contained"
            color="primary"
            onClick={handleCreateDialog}
          >
            Add New Data
          </Button>
        </div>

        <Grid container spacing={3} className={classes.formContainer}>
          <Grid item xs={12} md={6}>
            <TextField
              id="search-text"
              label="Search Name"
              fullWidth
              className={classes.inputWidth}
              value={mainState.searchValue}
              onChange={handleChange("searchValue")}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomSelect
              fullWidth
              label="Video Source Type"
              stateKey="type"
              value={mainState.type}
              items={mainState.typeArr}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      </Paper>

      <CustomTable
        headCells={header}
        rows={body}
        page={mainState.page}
        pageItems={mainState.pageItems}
        rowsPerPage={mainState.rowsPerPage}
        order={mainState.order}
        orderBy={mainState.orderBy}
        setState={setMainState}
      />
    </Container>
  );
}

export default PreEditVideoList;
