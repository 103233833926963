import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom';
import clsx from "clsx";
import CountUp from 'react-countup';

// material UI
import {Container, Grid, Paper, Typography} from "@material-ui/core";

// components
import { utilsContext } from '../contexts/utils';
import dashboard from "../api/dashboard";

const countDuration = 5;

const useStyles = makeStyles(theme => ({
  special: {
    color: 'red',
    fontSize: 30
  },
  wrapper: {
    backgroundColor: theme.palette.secondary
  },
  sections: {
    backgroundColor: 'yellow'
  },
  tabWrapper: {
    padding: 25,
  },
  // components
  tabTittle: {
    marginBottom: 13,
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.main,
    fontSize: 20,
    color: 'grey',
  },
  count: {
    fontSize: 28,
  },
  knowMore: {

    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    }
  },
  // common
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  flexJustifyCenter: {
    justifyContent: 'center'
  },
  flexJustifySpaceEvenly: {
    justifyContent: 'space-evenly'
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between'
  }
}));


function Dashboard() {
  const classes = useStyles();
  const { _handleChange, theme } = useContext(utilsContext);
  const [redirect, setRedirect] = useState(false);
  const [mainState, _setMainState] = useState({
    list: [],
  });

  const setMainState = (newState) => {
    _setMainState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    async function inital() {
      // await getCategory();
      _handleChange({ openBackdrop: true });
      const { success, data } = await dashboard.getDashboard();

      _handleChange({ openBackdrop: false });

      if (!success) {
       return  _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: 'Fail to get dashboard data' });
      }

      const list = [
        {name: 'Videos', value: data.videos, path: '/videos'},
        {name: 'Categories', value: data.categories, path: '/taxonomic/categories'},
        {name: 'Tags', value: data.tags, path: '/taxonomic/tags'},
        {name: 'Models', value: data.models, path: '/taxonomic/models'},
      ];

      setMainState({list})
    }
    inital();
  }, []);

  const changePage = (path) => event => {
    setRedirect(path);
  };

  const syncButton = async () => {
    _handleChange({ openBackdrop: true });
    const { success } = await dashboard.temSyncVideo();

    _handleChange({ openBackdrop: false });
    if (!success) {
      _handleChange({ snackbar: true, snackbarType: 1, snackbarContent: 'Fail to sync data' });
    } else {
      _handleChange({ snackbar: true, snackbarType: 0, snackbarContent: 'Success to sync data' });
    }
  }

  return (
    <Container className={classes.tableWrapper}>

      {redirect && <Redirect to={redirect} push/>}

      <Grid
        container
        spacing={5}
        justify="space-between"
        className={clsx(classes.dynamicInputWrapper, classes.spacing)}
      >
        {mainState.list.map((e, i) => (
          <Grid
            key={`list-${i}`}
            item
            md={4}
          >
            <Paper className={classes.tabWrapper}>
              <Typography className={classes.tabTittle}>{e.name}</Typography>
              <div className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween)}>
                <CountUp
                  className={classes.count}
                  duration={countDuration}
                  end={e.value}
                />
                <Typography
                  className={classes.knowMore}
                  onClick={changePage(e.path)}
                >
                  Click to know more
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}

        {/*<Grid item md={4}>*/}
        {/*  <Paper className={classes.tabWrapper}>*/}
        {/*    <Typography className={classes.tabTittle}>Temporally Sync</Typography>*/}
        {/*    <div className={clsx(classes.flexCenter, classes.flexJustifySpaceBetween)}>*/}

        {/*      <Typography*/}
        {/*          className={classes.knowMore}*/}
        {/*          onClick={syncButton}*/}
        {/*      >*/}
        {/*        Click to sync*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*  </Paper>*/}
        {/*</Grid>*/}
      </Grid>
    </Container>
  )
}

export default Dashboard;
